import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import PriorityExclusiveCard from './PriorityExclusiveCard'
import useMedia from 'hooks/useMedia'
import { useNavigate } from 'react-router-dom'
import { reqGiftList } from 'apis/lp/actions'
import { getFontSize, device } from 'style/devices'

const PriorityExclusiveSection = (props) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { categoryCode } = props
    const [gifts, setGifts] = useState([])
    const minW = useMedia(device.tablet)
    const sildeOffset = minW ? 24 : 12
    const urlParams = new URLSearchParams(location.search)

    const giftCategoryList = [
        {
            label: t('reward.categoryList.all'),
            value: 'ALL_CATEGORY',
        },
        {
            label: t('reward.categoryList.priorityPick'),
            value: 'PRIORITY_PICK',
        },
        {
            label: t('reward.categoryList.priorityExperience'),
            value: 'EDUCATIONAL_WORKSHOPS',
        },
        {
            label: t('reward.categoryList.racingExclusive'),
            value: 'RACING_AND_EXCLUSIVE',
        },
        {
            label: t('reward.categoryList.sportsWellness'),
            value: 'SPORTS_AND_WELLNESS',
        },
        {
            label: t('reward.categoryList.gourmet'),
            value: 'GOURMET',
        },
        {
            label: t('reward.categoryList.homeGadget'),
            value: 'HOME_AND_GADGET',
        },
        {
            label: t('reward.categoryList.entertrainment'),
            value: 'ENTERTAIMENT',
        },
        {
            label: t('reward.categoryList.travel'),
            value: 'TRAVEL',
        },
        {
            label: t('reward.categoryList.fashionBeauty'),
            value: 'FASHION_AND_BEAUTY',
        },
        {
            label: t('reward.categoryList.voucher'),
            value: 'VOUCHER',
        },
    ]

    const title = giftCategoryList.find(
        (giftCategory) => giftCategory.value === categoryCode
    )?.label

    const toSeeAll = (giftCategoryCode) => {
        urlParams.set('categoryCode', giftCategoryCode)
        const internalAd = `SPLDSP_CWL/Home_${giftCategoryCode}_All`
        navigate(`/rewards?${urlParams.toString()}`, {
            state: {
                internalAd,
                id: 'rewards',
            },
        })
    }

    const itemClick = (giftId) => {
        urlParams.set('rewardId', giftId)
        const internalAd = `SPLDSP_CWL/Home_${categoryCode}_Page`
        console.log(internalAd)
        navigate(`/reward-detail?${urlParams.toString()}`, {
            state: {
                internalAd,
            },
        })
    }

    useEffect(() => {
        const getGiftsByCategory = async (category) => {
            const parameters = {
                category: categoryCode,
                limit: 10,
                isFeatured: 1,
            }
            try {
                const result = await reqGiftList(parameters)
                const giftList = result?.data?.object?.data || []
                console.log(giftList)
                setGifts(giftList)
            } catch (error) {
                console.log(error)
            }
        }
        getGiftsByCategory()
    }, [categoryCode])

    return (
        <ExclusiveBannerCardContainer>
            {title != null && (
                <ExclusiveBannerHeadView>
                    <ExclusiveBannerTitleView>{title}</ExclusiveBannerTitleView>
                    <ExclusiveBannerDescriptionView
                        onClick={() => toSeeAll(categoryCode)}
                    >
                        {t('pcarddetail.seeall')}
                    </ExclusiveBannerDescriptionView>
                </ExclusiveBannerHeadView>
            )}
            <SwiperContainer>
                <Swiper
                    spaceBetween={8}
                    slidesPerView={'auto'}
                    slidesOffsetBefore={sildeOffset}
                    slidesOffsetAfter={sildeOffset}
                >
                    {gifts.map((obj, i) => {
                        return (
                            <ExclusiveBannerItem
                                key={i}
                                onClick={() => {
                                    itemClick(obj.giftId)
                                }}
                            >
                                <PriorityExclusiveCard
                                    img={`${obj.giftImage}`}
                                    label={obj.giftName}
                                    point={obj.points}
                                    pointsWithCash={obj.pointsWithCash}
                                    cashAmtWithPoints={obj.cashAmtWithPoints}
                                />
                            </ExclusiveBannerItem>
                        )
                    })}
                </Swiper>
            </SwiperContainer>
        </ExclusiveBannerCardContainer>
    )
}

const ExclusiveBannerCardContainer = styled.div`
    box-sizing: border-box;
    margin-top: 24rem;
    @media ${device.tablet} {
        margin-top: 24px;
    }
`
const ExclusiveBannerHeadView = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12rem;
    margin-right: 12rem;
    padding-left: 12rem;
    @media ${device.tablet} {
        margin-bottom: 12px;
        margin-right: 24px;
        padding-left: 24px;
    }
`
const ExclusiveBannerTitleView = styled.div`
    color: #000000;
    font-size: ${getFontSize()?.mobileFontStyle.title1.fontSize}rem;
    font-weight: 700;
    line-height: ${getFontSize()?.mobileFontStyle.title1.lineHeight}rem;
    @media ${device.tablet} {
        font-size: ${getFontSize()?.tabletFontStyle.title1.fontSize}px;
        line-height: ${getFontSize()?.tabletFontStyle.title1.lineHeight}px;
    }
`

const SwiperContainer = styled.div``

const ExclusiveBannerDescriptionView = styled.div`
    color: #122c68;
    font-size: ${getFontSize()?.mobileFontStyle.label2.fontSize}rem;
    font-weight: 500;
    line-height: ${getFontSize()?.mobileFontStyle.label2.lineHeight}rem;
    @media ${device.tablet} {
        font-size: ${getFontSize()?.tabletFontStyle.label2.fontSize}px;
        line-height: ${getFontSize()?.tabletFontStyle.label2.lineHeight}px;
    }
`
const ExclusiveBannerItem = styled(SwiperSlide)`
    width: 248rem;
    @media ${device.tablet} {
        width: 248px;
    }
`

export default PriorityExclusiveSection
