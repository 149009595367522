import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { device } from 'style/devices'
import { useLocation } from 'react-router-dom'
import ExplainationTop from 'components/Explanation/ExplainationTop'
import AboutCollapse from 'components/Explanation/AboutCollapse'
import { AboutList, remarkList } from 'mock/fake'
import { useTranslation } from 'react-i18next'

const About = () => {
    const { t, i18n } = useTranslation()
    const { cuno } = useSelector((state) => state.urlParams)
    const { memberProfile } = useSelector((state) => state.profile)
    const location = useLocation()

    useEffect(() => {
        const internalAd = location.state?.internalAd
        window.adobeDataLayer?.push({
            event: 'pageview',
            page: {
                name: '',
                id: 'CWL-ClubWideLoyalty',
                language: i18n.language,
            },
            user: {
                cuno,
                segment_code: memberProfile?.segmentCode,
                pcard_tier: memberProfile?.ptierCode,
            },
            internal_ad: internalAd,
            report_suite: '3in1 App',
        })
    }, [])

    return (
        <Container>
            <ExplainationTop title={t('about.title')} />
            <ExplainationContent>
                <ExplainationWrap>
                    {AboutList.map((item) => (
                        <CollapseWrap key={item.title}>
                            <AboutCollapse
                                title={t(item.title)}
                                descriptiton={item.descriptiton}
                                textList={item.textList}
                                sections={item.sections}
                                tips={t(item.tips)}
                            />
                        </CollapseWrap>
                    ))}
                    {/* <CollapseWrap>
                        <AboutCollapse title="Join our programme">
                            <AboutJoinInfo />
                        </AboutCollapse>
                    </CollapseWrap> */}
                    <CollapseWrap>
                        <AboutCollapse title={t('about.remark')}>
                            {remarkList.map((item, index) => (
                                <TextListUl key={item}>
                                    <Number>{index + 1}</Number>
                                    <Text>{t(item)}</Text>
                                </TextListUl>
                            ))}
                        </AboutCollapse>
                    </CollapseWrap>
                </ExplainationWrap>
            </ExplainationContent>
        </Container>
    )
}

const Container = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: #f0f0f0;
`

const ExplainationContent = styled.div`
    width: 100%;
    background: #f0f0f0;
    padding: 0 20px;
    @media ${device.tablet} {
        padding: 0 20rem;
    }
`

const ExplainationWrap = styled.div`
    z-index: 2;
    position: relative;
`

const CollapseWrap = styled.div`
    margin-bottom: 20rem;
    @media ${device.tablet} {
        margin-bottom: 20px;
    }
`

const TextListUl = styled.div`
    display: flex;
`

const Number = styled.div`
    width: 12rem;
    height: 12rem;
    flex-shrink: 0;
    color: #8c8c8c;
    @media ${device.tablet} {
        width: 12px;
        height: 12px;
    }
`

const Text = styled.div`
    font-size: 14rem;
    @media ${device.tablet} {
        font-size: 14px;
    }
    color: #8c8c8c;
`

export default About
