import React from 'react'
import PriorityRegistrationDeclareComponent from 'components/PriorityRegistrationPage/PriorityRegistrationDeclareComponent'
import styled from 'styled-components'
import { device } from 'style/devices'
import { useTranslation } from 'react-i18next'

const TermsConditions = () => {
    const { t } = useTranslation()
    const urlParams = new URLSearchParams(window.location.search)
    const isReceive = urlParams.get('isReceive') || 'false'
    const isReceivePage = isReceive === 'true'
    const rendercommunications = () => {
        return <Wrap>{t('PriorityRegistration.notReceive')}</Wrap>
    }
    return (
        <Container>
            {!isReceivePage ? (
                <PriorityRegistrationDeclareComponent></PriorityRegistrationDeclareComponent>
            ) : (
                rendercommunications()
            )}
        </Container>
    )
}

const Container = styled.div`
    padding: 12rem 14rem;
    @media ${device.tablet} {
        padding: 20px;
    }
`

const Wrap = styled.div`
    font-size: 13rem;
    font-weight: 400;
    @media ${device.tablet} {
        font-size: 16px;
    }
`

export default TermsConditions
