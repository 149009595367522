import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { device } from 'style/devices'
import styled from 'styled-components'
import phone from 'assets/icons/phone.png'
import faq from 'assets/icons/faq.png'
import rightarrow from 'assets/icons/rightarrow.png'
import PermissionShow from 'components/permission/show'
import useDidMount from 'utils/useDidMount'
import { useLoading } from 'components/Loading/context'
import { getCroMappings } from 'apis/mvp/actions'

const ContactUsPage = () => {
    const { showLoading, hideLoading } = useLoading()
    const { cuno } = useSelector((state) => state.urlParams)
    const { memberProfile } = useSelector((state) => state.profile)
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const [isDisplayPhoneCall, setDisplayPhoneCall] = useState(false)
    const [croContact, setCroContact] = useState(null)

    console.log(croContact)

    const navigateToFaq = () => {
        const internalAd = 'SPLDSP_CWL/Help_FAQ'
        navigate(`/faq${location.search}`, {
            state: {
                internalAd,
            },
        })
    }

    const displayPhoneCall = () => {
        if (memberProfile?.pTier === 'Black') return true
        if (
            memberProfile?.memNo &&
            memberProfile.memNo !== '' &&
            memberProfile?.pTier === 'Gold'
        ) {
            return true
        } else {
            return false
        }
    }

    const call = (phoneNo) => {
        window.adobeDataLayer?.push({
            event: 'interaction',
            interaction: {
                eventID: 'CWL_Click_Help-CallCRO',
            },
            page: {
                name: '',
                id: 'CWL-ClubWideLoyalty',
                language: i18n.language,
            },
            user: {
                cuno,
                segment_code: memberProfile?.segmentCode,
                pcard_tier: memberProfile?.ptierCode,
            },
            report_suite: '3in1 App',
        })
        window.open(`tel:${phoneNo}`)
    }

    useDidMount(() => {
        showLoading()
    })

    useEffect(() => {
        setDisplayPhoneCall(displayPhoneCall())
    }, [memberProfile])

    useEffect(() => {
        // const CDN_PATH =
        //     process.env.REACT_APP_PREVIEW_VERSION === 'true'
        //         ? process.env.REACT_APP_CDN_PREVIEW_PATH
        //         : process.env.REACT_APP_CDN_PATH
        // axios
        //     .get(
        //         `https://${CDN_PATH}/-/Media/sites/LoyaltyEB/media/app/croMappings?t=${Math.random()}`
        //     )
        //     .then((result) => {
        //         console.log(result)
        //         const croList = result?.data
        //         if (croList) {
        //             const contact = croList.find(
        //                 (cro) =>
        //                     cro.betAccountNo === memberProfile?.betAccountNo
        //             )
        //             setCroContact(contact)
        //         }
        //         hideLoading()
        //     })
        //     .catch((error) => {
        //         console.log(error)
        //         hideLoading()
        //     })
        const body = {}
        const headers = {
            'X-Request-JWT': localStorage.getItem('api-token'),
            'X-Request-AccEntryCode': 'Jwt',
        }
        getCroMappings(body, headers, memberProfile?.betAccountNo)
            .then((response) => {
                console.log('croContact responses', response)
                const matchedCroContact = response.data.data
                if (matchedCroContact?.name && matchedCroContact?.phone)
                    setCroContact(matchedCroContact)
                hideLoading()
            })
            .catch((error) => {
                console.log(error)
                hideLoading()
            })

        const internalAd = location.state?.internalAd
        window.adobeDataLayer?.push({
            event: 'pageview',
            page: {
                name: '',
                id: 'CWL-ClubWideLoyalty',
                language: i18n.language,
            },
            user: {
                cuno,
                segment_code: memberProfile?.segmentCode,
                pcard_tier: memberProfile?.ptierCode,
            },
            internal_ad: internalAd,
            report_suite: '3in1 App',
        })
    }, [])

    return (
        <div className="ContactUsPageContainer">
            <div className="ContactUsPageWrap">
                <PermissionShow name="DesignatedPriorityCustomer">
                    {isDisplayPhoneCall && croContact ? (
                        <div className="ContactUsCard">
                            <CardTitle>{t('contactus.managerTitle')}</CardTitle>
                            <CardBody>{`${t(`contactus.name`)}: ${croContact?.name}`}</CardBody>
                            <ActionButton
                                onClick={() => call(croContact?.phone)}
                            >
                                <ActionIcon src={phone} />
                                <ActionButtonText>
                                    {`${t('contactus.call')} ${croContact?.name}`}
                                </ActionButtonText>
                            </ActionButton>
                        </div>
                    ) : null}
                </PermissionShow>
                <div className="FAQCardContainer">
                    <PermissionShow name="faq">
                        <FAQCard onClick={navigateToFaq}>
                            <FAQIcon src={faq} />
                            <FAQText>{t('contactus.faq')}</FAQText>
                            <FAQButton src={rightarrow}></FAQButton>
                        </FAQCard>
                    </PermissionShow>
                </div>
            </div>
        </div>
    )
}

const CardTitle = styled.div`
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
`

const CardBody = styled.div`
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 8px;
`

const ActionButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40rem;
    border-radius: 100rem;
    margin-top: 16rem;
    background-color: #003c84;
    position: relative;
    flex-shrink: 0;
    @media ${device.tablet} {
        margin-top: 16px;
        height: 40px;
        border-radius: 100px;
    }
`

const ActionIcon = styled.img`
    /* background-color: white; */
    position: absolute;
    left: 12px;
    width: 24px;
    height: 24px;
`

const ActionButtonText = styled.div`
    width: 100%;
    text-align: center;
    font-size: 16rem;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0.1px;
    text-align: center;
    color: #ffffff;
    @media ${device.tablet} {
        font-size: 18px;
    }
`

const FAQCard = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-radius: 8px;
    background-color: white;
    padding: 8rem 0px 8rem 12rem;
    box-shadow: 0px 4px 4px 0px #00000026;
    @media ${device.tablet} {
        padding: 8px 0px 8px 12px;
    }
`

const FAQIcon = styled.img`
    width: 24px;
    height: 24px;
`

const FAQText = styled.div`
    font-size: 16rem;
    font-weight: 500;
    letter-spacing: 0.1px;
    text-align: left;
    margin-left: 8rem;
    @media ${device.tablet} {
        font-size: 18px;
        margin-left: 8px;
    }
`

const FAQButton = styled.img`
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0px;
`

export default ContactUsPage
