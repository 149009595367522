import React, { useContext } from 'react'

export const LoadingContext = React.createContext({
    loading: false,
    showLoading: () => {},
    hideLoading: () => {},
})
export const useLoading = () => {
    const context = useContext(LoadingContext)
    if (context === undefined) {
        throw new Error('useToast must be used within a ToastContainer')
    }
    return context
}
