import styled from 'styled-components'
import { device } from 'style/devices'

const Button = ({
    children,
    disabled,
    type = 'primary',
    onClick,
    ButtonType,
}) => {
    const buttonClick = () => {
        onClick && onClick()
    }

    return (
        <ButtonWapper
            disabled={disabled}
            type={ButtonType}
            $type={type}
            $disabled={disabled}
            onClick={buttonClick}
        >
            {children}
        </ButtonWapper>
    )
}

const ButtonWapper = styled.button`
    background: ${(props) =>
        props.$disabled
            ? '#ccc'
            : props.$type === 'primary'
              ? '#003C84;'
              : '#fff'};
    color: ${(props) =>
        props.$disabled
            ? '#8b8b8b'
            : props.$type === 'primary'
              ? 'white'
              : '#003C84'};
    display: flex;
    border: ${(props) =>
        props.$disabled ? '1px solid #ccc' : '1px solid #003c84'};
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40rem;
    border-radius: 100rem;
    margin-top: 8rem;
    font-weight: 500rem;
    @media ${device.tablet} {
        height: 40px;
        border-radius: 100px;
        margin-top: 8px;
        font-weight: 500px;
    }
`
export default Button
