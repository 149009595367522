import axios from 'axios'
import i18next from 'i18next'
import configs from 'configs/configs'
const MAX_RETRIES = configs.API_MAX_RETRIES || 3

const lpServer = axios.create({
    baseURL: process.env.REACT_APP_LP_API,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
})

const refreshAndRetryQueue = []
let isRefreshing = false

const refreshAccessToken = async () => {
    console.log('refreshed')
}

lpServer.interceptors.request.use(
    (config) => {
        const jcJWT =
            localStorage.getItem('api-token') || process.env.REACT_APP_DEV_JWT

        if (jcJWT) {
            config.headers.jcJWT = jcJWT
        }
        config.headers.lang = getCurrentLang()
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

// Add global response interceptor
lpServer.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config
        originalRequest.__retryCount = originalRequest.__retryCount || 0

        if (originalRequest.__retryCount >= MAX_RETRIES)
            return Promise.reject(error)

        if (error.response && error.response.status === 401) {
            if (!isRefreshing) {
                isRefreshing = true
                try {
                    // Refresh the access token
                    const newAccessToken = await refreshAccessToken()

                    // Update the request headers with the new access token
                    error.config.headers.Authorization = `Bearer ${newAccessToken}`

                    // Retry all requests in the queue with the new token
                    refreshAndRetryQueue.forEach(
                        ({ config, resolve, reject }) => {
                            lpServer
                                .request(config)
                                .then((response) => resolve(response))
                                .catch((err) => reject(err))
                        }
                    )

                    // Clear the queue
                    refreshAndRetryQueue.length = 0
                    originalRequest.__retryCount += 1
                    // Retry the original request
                    return lpServer(originalRequest)
                } catch (refreshError) {
                    console.log(refreshError)
                    throw refreshError
                } finally {
                    isRefreshing = false
                }
            }

            // Add the original request to the queue
            return new Promise((resolve, reject) => {
                refreshAndRetryQueue.push({
                    config: originalRequest,
                    resolve,
                    reject,
                })
            })
        }

        // Return a Promise rejection if the status code is not 401
        return Promise.reject(error)
    }
)

const getCurrentLang = () => {
    let lang = i18next.language
    if (i18next.language === 'en-US') {
        lang = 'en'
    }
    if (i18next.language === 'zh-HK') {
        lang = 'tc'
    }
    return lang
}

export default lpServer
