import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import { device } from 'style/devices'
import PriorityStatusWidget from './PriorityStatusWidget'
import PriorityRewardsWidget from './PriorityRewardsWidget'
import { isPermissionShow } from 'components/permission/show'

const PriorityCardWidgetView = () => {
    const [items, setItems] = useState([])
    const [sliderNum, setSliderNum] = useState(1)
    const [widthPercent, setWidthPercent] = useState('0')
    const priorityStatus = isPermissionShow({
        name: 'priority-status-button',
        url: 'priority-status',
    })
    const priorityReward = isPermissionShow({ url: 'priority-reward' })
    useEffect(() => {
        const temp = [
            {
                type: 'pcard',
                title: 'pcard-details.priorityStatus',
                des: 'pcard-details.priorityStatusDetail',
                subdes: 'pcard-details.priorityStatusNumber',
            },
            {
                type: 'common',
                title: 'pcard-details.Reward',
                des: 'pcard-details.RewardDetail',
                subdes: 'pcard-details.RewardNumber',
            },
        ]
        setItems(
            temp.filter(
                (i) =>
                    (i.type === 'pcard' && priorityStatus) ||
                    (i.type === 'common' && priorityReward)
            )
        )
    }, [])

    useEffect(() => {
        let tempNum = 1
        let tempWidth = '0'
        if (items.length === 1) {
            tempNum = 1
            tempWidth = '100%'
        } else if (items.length === 2) {
            tempNum = 2
            tempWidth = '50%'
        } else if (items.length === 3) {
            tempNum = 3
            tempWidth = '33%'
        } else if (items.length > 3) {
            tempNum = 4
            tempWidth = '28%'
        }
        setSliderNum(tempNum)
        setWidthPercent(tempWidth)
    }, [items])
    return (
        <StatusCardContainer $mr={sliderNum > 3 ? '0' : '12'}>
            {items.length > 0 ? (
                <Swiper
                    style={{ width: '100%', overflow: 'visible' }}
                    spaceBetween={8}
                    slidesPerView={sliderNum}
                >
                    {items.map((obj, index) => {
                        if (obj.type === 'pcard') {
                            return (
                                <WidgeSwiperItem
                                    key={`${index}pcard`}
                                    wd={widthPercent}
                                >
                                    <PriorityStatusWidget data={obj} />
                                </WidgeSwiperItem>
                            )
                        } else if (obj.type === 'common') {
                            return (
                                <WidgeSwiperItem
                                    wd={widthPercent}
                                    key={`${index}common`}
                                >
                                    <PriorityRewardsWidget data={obj} />
                                </WidgeSwiperItem>
                            )
                        } else {
                            return null
                        }
                    })}
                </Swiper>
            ) : (
                <WidgeSwiperPlaceholdItem></WidgeSwiperPlaceholdItem>
            )}
        </StatusCardContainer>
    )
}

const StatusCardContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin-left: 12rem;
    margin-right: ${(props) => props.$mr}rem;
    @media ${device.tablet} {
        margin-left: 24px;
        margin-right: 24px;
    }
`
const WidgeSwiperItem = styled(SwiperSlide)`
    width: ${(props) => props.wd};
    border-radius: 12rem;
    overflow: hidden;
    box-shadow: 0px 4px 12px 0px rgb(0 0 0 / 15%);
    z-index: 2;
    position: relative;
    @media ${device.tablet} {
        border-radius: 12px;
    }
`
const WidgeSwiperPlaceholdItem = styled.div`
    height: 120rem;
    width: 100%;
    box-sizing: border-box;
    @media ${device.tablet} {
        height: 120px;
    }
`
export default PriorityCardWidgetView
