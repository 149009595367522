import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { device } from 'style/devices'

const Collapse = (props) => {
    const { title, descriptiton, textList, tips, children, sections } = props
    const { t } = useTranslation()
    return (
        <CardBox>
            <Header>
                <Title>{title}</Title>
            </Header>
            {descriptiton && <Content>{t(descriptiton)}</Content>}
            {sections && (
                <Content>
                    {sections?.map((item) => (
                        <Section key={item.text}>{t(item.text)}</Section>
                    ))}
                </Content>
            )}

            {textList && (
                <Content>
                    {textList.map((item) => (
                        <TextListUl key={item.text}>
                            <Dot />
                            <Text>{t(item.text)}</Text>
                        </TextListUl>
                    ))}
                    {tips && <Tips>{tips}</Tips>}
                </Content>
            )}
            {children && <Content>{children}</Content>}
        </CardBox>
    )
}

const CardBox = styled.div``

const Header = styled.div`
    background: #f7f7f7;
    height: 50rem;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0 8rem;
    box-sizing: border-box;
    @media ${device.tablet} {
        height: 50px;
        padding: 0 8px;
    }
`

const Title = styled.div`
    font-size: 16rem;
    font-weight: 400;
    @media ${device.tablet} {
        font-size: 16px;
    }
`

const Content = styled.div`
    width: 100%;
    padding: 12rem;
    background: #fff;
    font-size: 14rem;
    font-weight: 400;
    @media ${device.tablet} {
        padding: 12px;
        font-size: 14px;
    }
`
const TextListUl = styled.div`
    display: flex;
`

const Dot = styled.div`
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background: #000;
    flex-shrink: 0;
    margin-top: 8rem;
    margin-right: 6rem;
    @media ${device.tablet} {
        margin-top: 8px;
        margin-right: 6px;
        width: 4px;
        height: 4px;
    }
`

const Text = styled.div`
    font-size: 14rem;
    @media ${device.tablet} {
        font-size: 14px;
    }
`

const Tips = styled.div`
    color: #8c8c8c;
    font-size: 14rem;
    background: #fff;
    @media ${device.tablet} {
        font-size: 14px;
    }
`

const Section = styled.div`
    margin-bottom: 4rem;
    &:last-child {
        margin-bottom: 0;
    }
    @media ${device.tablet} {
        margin-bottom: 4px;
    }
`

export default Collapse
