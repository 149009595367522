import React from 'react'
import styled from 'styled-components'
import { device } from 'style/devices'

const Radio = (props) => {
    const { text, checked, setChecked } = props
    const handleChecked = () => {
        setChecked(!checked)
    }
    return (
        <Container onClick={handleChecked} $primary={checked}>
            {text && <Text $primary={checked}>{text}</Text>}
            <Dot $primary={checked}></Dot>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    background: ${(props) => (props.$primary ? '#003C84' : '#ccc')};
    flex-direction: ${(props) => (props.$primary ? 'row' : 'row-reverse')};
    width: 54rem;
    height: 24rem;
    align-items: center;
    position: relative;
    border-radius: 100rem;
    justify-content: space-between;
    padding: 0 2rem;
    @media ${device.tablet} {
        width: 54px;
        height: 24px;
        align-items: center;
        position: relative;
        border-radius: 100px;
    }
`

const Text = styled.div`
    color: #fff;
    width: 28rem;
    font-size: 12rem;
    text-align: center;
    position: relative;
    left: ${(props) => (props.$primary ? '0px' : '-2px')};
    @media ${device.tablet} {
        width: 28px;
        font-size: 14px;
    }
`

const Dot = styled.div`
    background: #f0f0f0;
    width: 20rem;
    height: 20rem;
    border-radius: 50%;
    @media ${device.tablet} {
        width: 20px;
        height: 20px;
    }
`

export default Radio
