import React from 'react'
import styled from 'styled-components'
import { device } from 'style/devices'

const TierBenefitIconTitle = (props) => {
    const { imgUrl, title } = props
    return (
        <Container>
            <Icon src={imgUrl}></Icon>
            <Title>{title}</Title>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    align-items: center;
`
const Icon = styled.img`
    width: 50rem;
    height: 50rem;
    margin-right: 12rem;
    @media ${device.tablet} {
        width: 50px;
        height: 50px;
        margin-right: 12px;
    }
`

const Title = styled.div`
    color: #bd8945;
    font-size: 14rem;
    font-weight: 700;
    line-height: 16rem;
    @media ${device.tablet} {
        font-size: 16px;
    }
`

export default TierBenefitIconTitle
