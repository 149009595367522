import React from 'react'
import styled from 'styled-components'
import TrophyIcon from 'assets/icons/icon_trophy.png'
import { getFontSize, device } from 'style/devices'
import ImageWithError from 'components/RewardsPage/ImageWithError'
import { numberWithCommas } from 'utils/common'
import { useTranslation } from 'react-i18next'

const PrioritySpecialCard = ({
    img,
    label,
    point,
    pointsWithCash,
    cashAmtWithPoints,
}) => {
    const { t } = useTranslation()
    return (
        <SpecialContainer>
            <SpecialBannerImage src={img} />
            <SpecialBottomView>
                <Label>{label}</Label>
                <SpecialPointView>
                    {/* <ExclusiveCardPointViewLine> */}
                    {Number(String(point)) >= 0 && (
                        <React.Fragment>
                            <Icon src={TrophyIcon} />{' '}
                            <NumberSpan>{numberWithCommas(point)}</NumberSpan>{' '}
                        </React.Fragment>
                    )}
                    {Number(String(point)) >= 0 &&
                        (Number(String(pointsWithCash)) >= 0 ||
                            Number(String(cashAmtWithPoints)) >= 0) && (
                            <RewardCardDollarOr>
                                {t('reward.or')}
                            </RewardCardDollarOr>
                        )}{' '}
                    {/* </ExclusiveCardPointViewLine>
                    <ExclusiveCardPointViewLine> */}
                    {Number(String(pointsWithCash)) >= 0 && (
                        <PointSpan>
                            <Icon src={TrophyIcon}></Icon>{' '}
                            {numberWithCommas(pointsWithCash)}
                        </PointSpan>
                    )}
                    {Number(String(pointsWithCash)) >= 0 &&
                        Number(String(cashAmtWithPoints)) >= 0 && (
                            <NumberSpan>+</NumberSpan>
                        )}
                    {Number(String(cashAmtWithPoints)) >= 0 && (
                        <NumberSpan>{` $${numberWithCommas(cashAmtWithPoints)}`}</NumberSpan>
                    )}
                    {/* </ExclusiveCardPointViewLine> */}
                </SpecialPointView>
            </SpecialBottomView>
        </SpecialContainer>
    )
}

const SpecialContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 8rem;
    width: 160rem;
    height: 225rem;
    box-sizing: border-box;
    @media ${device.tablet} {
        width: 160px;
        height: 267px;
        border-radius: 8px;
    }
`
const SpecialBannerImage = styled(ImageWithError)`
    width: 100%;
    height: 90rem;
    border-top-left-radius: 8rem;
    border-top-right-radius: 8rem;
    display: block;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 78.46%,
        rgba(0, 0, 0, 0.1) 110.38%
    );
    @media ${device.tablet} {
        height: 105px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
`

const SpecialBottomView = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    padding: 8rem 12rem;
    border-bottom-left-radius: 8rem;
    border-bottom-right-radius: 8rem;
    height: 135rem;
    @media ${device.tablet} {
        height: 162px;
        padding: 8px 12px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
`

// const ExclusiveCardPointViewLine = styled.div`
//     display: flex;
//     width: 100%;
// `

const RewardCardDollarOr = styled.span`
    font-size: 13rem;
    background-color: #e2edf6;
    border-radius: 10rem;
    padding: 0 5rem;
    display: inline-block;
    margin: 0 5rem;
    line-height: 20rem;
    @media ${device.tablet} {
        font-size: 13px;
        padding: 0 5px;
        border-radius: 10px;
        margin: 0 5px;
        line-height: 20px;
    }
`
const PointSpan = styled.span`
    line-height: 20rem;
    display: flex;
    align-items: center;
    font-size: 16rem;
    @media ${device.tablet} {
        font-size: 20px;
        line-height: 26px;
    }
`
const Label = styled.div`
    color: #000;
    font-size: ${getFontSize()?.mobileFontStyle.label1.fontSize}rem;
    font-weight: 500;
    line-height: ${getFontSize()?.mobileFontStyle.label1.lineHeight}rem;
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    @media ${device.tablet} {
        font-size: 18px;
        line-height: 24px;
    }
`
const NumberSpan = styled.span`
    font-size: 16rem;
    line-height: 20rem;
    @media ${device.tablet} {
        font-size: 20px;
        line-height: 26px;
    }
`
const SpecialPointView = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 4px;
    // flex-direction: column;
`

const Icon = styled.img`
    width: 20rem;
    height: 20rem;
    /* margin-top: 2rem; */
    position: relative;
    /* top: 3rem; */
    vertical-align: middle;
    margin-right: 2rem;
    @media ${device.tablet} {
        width: 20px;
        height: 20px;
        margin-right: 2px;
    }
`
export default PrioritySpecialCard
