import asioxInstance from './index'
import configs from 'configs/configs'

export function accountValidation(body, headers, accountNo) {
    return asioxInstance.post(
        `${configs.ACCOUNT_VALIDATION}/${accountNo}`,
        body,
        { headers }
    )
}

export function login(body, headers) {
    return asioxInstance.post(configs.LOGIN, body, { headers })
}

export function getMemberProfile(body, headers) {
    return asioxInstance.post(configs.MEMBER_PROFILE, body, { headers })
}
export function getTransactionRecords(body, headers) {
    return asioxInstance.post(configs.MEMBER_TRANSACTIONS, body, { headers })
}
export function getMemberAddress(body, headers) {
    return asioxInstance.post(configs.CWL_ADDRESS, body, { headers })
}

export function setDirectMarketingFlap(body, headers, path) {
    return asioxInstance.post(
        `${configs.DIRECT_MARKETING_FLAP}/${path}`,
        body,
        { headers }
    )
}

export function getCroMappings(body, headers, accountNo) {
    return asioxInstance.post(
        `${configs.CROMAPPING.replace('{betAccNo}', accountNo)}`,
        body,
        { headers }
    )
}

export function getPaymentHashValue(body, headers) {
    return asioxInstance.post(`${configs.PAYMENT_ENCRYPT}`, body, { headers })
}

export function appConfigs(body) {
    // return asioxInstance.get(`/appConfigs.json`)
    return asioxInstance.get(`/webViewConfigs`)
}
