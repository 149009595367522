import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { device } from 'style/devices'
import Lottie from 'lottie-react'
import bridge from 'utils/mobileJSBridge'
import animation from 'assets/animations/animation.json'
import RegistrationSuccessLogo from 'assets/icons/registrationSuccess.png'
import { useTranslation } from 'react-i18next'
import { getMemberProfile } from 'apis/mvp/actions'
import { setUserProfile, setProfileLoaded } from 'store/modules/profile'
import PermissionShow from 'components/permission/show'
import { convertMemberProfileResults } from 'utils/common'

const PriorityRegistrationSuccess = () => {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    const [nextPage, setNextPage] = useState(false)
    const { cuno } = useSelector((state) => state.urlParams)
    const { memberProfile } = useSelector((state) => state.profile)

    const getMemberProfileInformation = () => {
        const body = {
            lang: i18n.language?.toLocaleLowerCase() || 'en-us',
        }
        const headers = {
            'X-Request-JWT': localStorage.getItem('api-token'),
            'X-Request-AccEntryCode': 'Jwt',
        }
        getMemberProfile(body, headers)
            .then((response) => {
                const wcrmMemberProfile = response.data.data
                console.log('Member Profile responses', response)
                if (wcrmMemberProfile?.isOptIn) {
                    bridge.cwlStatusChange('1')
                    const memberProfile =
                        convertMemberProfileResults(wcrmMemberProfile)
                    dispatch(setUserProfile(memberProfile))
                    dispatch(setProfileLoaded())
                    setNextPage(true)
                } else {
                    setTimeout(function () {
                        console.log('retry get profile')
                        getMemberProfileInformation()
                    }, 1500)
                }
            })
            .catch((error) => {
                console.log(error, 'error')
            })
    }

    useEffect(() => {
        getMemberProfileInformation()
        window.adobeDataLayer?.push({
            event: 'pageview',
            page: {
                name: '',
                id: 'CWL-ClubWideLoyalty',
                language: i18n.language,
            },
            user: {
                cuno,
                segment_code: memberProfile?.segmentCode,
                pcard_tier: memberProfile?.ptierCode,
            },
            report_suite: '3in1 App',
        })
    }, [])

    const goToNextPage = () => {
        window.adobeDataLayer?.push({
            event: 'interaction',
            interaction: {
                eventID: 'CWL_Click_RegSuccess-Start',
            },
            page: {
                name: '',
                id: 'CWL-ClubWideLoyalty',
                language: i18n.language,
            },
            user: {
                cuno,
                segment_code: memberProfile?.segmentCode,
                pcard_tier: memberProfile?.ptierCode,
            },
            report_suite: '3in1 App',
        })

        nextPage && navigate(`/pcard-details${location.search}`)
    }

    return (
        <StatusConetent>
            <AnimationBox>
                <Lottie animationData={animation} loop={true} />
            </AnimationBox>
            <SuccessWrap>
                <Logo src={RegistrationSuccessLogo} />
                <Status>
                    <Text>{t('PriorityRegistrationStatus.welcome')}</Text>
                    <Text>{t('PriorityRegistrationStatus.success')}</Text>
                </Status>
                {/* <Info>{t('PriorityRegistrationStatus.successInfo')}</Info> */}
                <PermissionShow name="pcard-details">
                    <StatusButton
                        $disabled={!nextPage}
                        onClick={() => goToNextPage()}
                    >
                        {t('PriorityRegistrationStatus.started')}
                    </StatusButton>
                </PermissionShow>
            </SuccessWrap>
        </StatusConetent>
    )
}

const StatusConetent = styled.div`
    height: 100vh;
    position: relative;
    padding: 20rem 12rem 0;
    box-sizing: border-box;
    @media ${device.tablet} {
        padding: 20px 12px 0;
    }
`

const AnimationBox = styled.div`
    position: relative;
`

const SuccessWrap = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 125rem;
    width: 100%;
    padding: 0 12rem;
    box-sizing: border-box;
    left: 0px;
    @media ${device.tablet} {
        width: 100%;
        height: 360px;
        top: 160px;
        padding: 0 12px;
    }
`
const Status = styled.div`
    font-size: 18rem;
    font-weight: 700;
    line-height: 23rem;
    text-align: center;
    color: #000;
    margin-bottom: 12rem;
    padding: 0 28rem;
    @media ${device.tablet} {
        margin-bottom: 12px;
        line-height: 23px;
        font-size: 24px;
        padding: 0 28px;
    }
`

const StatusButton = styled.div`
    background: #003c84;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40rem;
    border-radius: 100rem;
    background: ${(props) => (props.$disabled ? '#ccc' : '#003C84')};
    color: ${(props) => (props.$disabled ? '#8b8b8b' : '#fff')};
    @media ${device.tablet} {
        height: 40px;
        border-radius: 100px;
        width: 70%;
    }
`

// const Info = styled.div`
//     font-size: 16rem;
//     font-weight: 400;
//     line-height: 21rem;
//     text-align: center;
//     color: #000;
//     margin-bottom: 14rem;
//     padding: 0 28rem;
//     @media ${device.tablet} {
//         font-size: 20px;
//         line-height: 21px;
//         margin-bottom: 24px;
//         padding: 0 28px;
//     }
// `

const Text = styled.div``

const Logo = styled.img`
    width: 223rem;
    height: 220rem;
    @media ${device.tablet} {
        width: 223px;
        height: 220px;
    }
`

export default PriorityRegistrationSuccess
