import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { device } from 'style/devices'

const OptOutContainer = (props) => {
    const { children } = props
    const { t } = useTranslation()
    return (
        <Container>
            <Title>
                <Sub>{t('optout.OPTOUT')}</Sub>
                <Sub>{t('optout.hkjc')}</Sub>
            </Title>
            {children}
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    background: #f0f0f0;
    display: flex;
    flex-direction: column;
    height: 100vh;
`

const Title = styled.div`
    color: #222;
    padding: 24rem 0 14rem;
    text-align: center;
    @media ${device.tablet} {
        padding: 24px 0 14px;
    }
`

const Sub = styled.div`
    font-size: 21rem;
    line-height: 30rem;
    @media ${device.tablet} {
        font-size: 50px;
        line-height: 60px;
    }
`

export default OptOutContainer
