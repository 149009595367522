import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { device } from 'style/devices'
import OptOutContainer from 'components/Explanation/OptOutContainer'
import IconRight from 'assets/icons/icon_right.png'
import { useTranslation } from 'react-i18next'
import { optOut } from 'apis/lp/actions'
import { useLoading } from 'components/Loading/context'
import bridge from 'utils/mobileJSBridge'
import PermissionShow from 'components/permission/show'
import Button from 'components/Button'
import { getMemberProfile } from 'apis/mvp/actions'

const OptOut = () => {
    const { showLoading, hideLoading } = useLoading()
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()
    const { memberProfile } = useSelector((state) => state.profile)
    const { source, cuno } = useSelector((state) => state.urlParams)
    const scrollRef = useRef(null)
    const [agree, setAgree] = useState(false)

    const handleAgree = () => {
        setAgree(!agree)
    }

    const getMemberProfileInformation = () => {
        const body = {
            lang: i18n.language?.toLocaleLowerCase() || 'en-us',
        }
        const headers = {
            'X-Request-JWT': localStorage.getItem('api-token'),
            'X-Request-AccEntryCode': 'Jwt',
        }
        getMemberProfile(body, headers)
            .then((response) => {
                console.log('Member Profile responses', response)
                hideLoading()
                bridge.cwlStatusChange('2')
                navigate(`/opt-out-success${location.search}`)
            })
            .catch((error) => {
                console.log(error, 'error')
                hideLoading()
                bridge.cwlStatusChange('2')
                navigate(`/opt-out-success${location.search}`)
            })
    }

    const optout = () => {
        if (agree) {
            showLoading()
            const headers = {}
            optOut(source, headers)
                .then((response) => {
                    const { data } = response
                    console.log('opt-out response status', data?.status)
                    const isoptOutSuccess = data?.status === 0
                    if (isoptOutSuccess) {
                        setTimeout(() => {
                            getMemberProfileInformation()
                        }, 3000)
                    } else {
                        hideLoading()
                        navigate(`/opt-out-failed${location.search}`)
                    }
                })
                .catch((error) => {
                    console.log(error)
                    hideLoading()
                    navigate(`/opt-out-failed${location.search}`)
                })
        }
    }

    useEffect(() => {
        window.adobeDataLayer?.push({
            event: 'pageview',
            page: {
                name: '',
                id: 'CWL-ClubWideLoyalty',
                language: i18n.language,
            },
            user: {
                cuno,
                segment_code: memberProfile?.segmentCode,
                pcard_tier: memberProfile?.ptierCode,
            },
            report_suite: '3in1 App',
        })
    }, [])
    const toTerms = () => {
        navigate('/terms-conditions')
    }
    return (
        <OptOutContainer>
            <Container>
                {/* <Text>{t('optout.title')}</Text> */}
                <InfoWrap ref={scrollRef}>
                    <ClauseList>
                        {/* <ClauseTitle>{t(item.title)}</ClauseTitle> */}
                        <ClauseListLi>
                            <ClauseText>{t('optout.processDesc')}</ClauseText>
                        </ClauseListLi>
                    </ClauseList>
                    <ClauseList>
                        <ClauseListLi>
                            <ClauseText>
                                {i18n.language !== 'en-US' ? (
                                    <>
                                        根據香港賽馬會「優越」計劃之
                                        <A onClick={toTerms}>條款及細則</A>
                                        ，客戶所累積的 WINS
                                        及其他權益、禮遇和獎賞將被即時取消，恕不作出任何補償及退款。
                                    </>
                                ) : (
                                    <>
                                        All Accumulated WINS and other rights,
                                        privileges and benefits of a Customer
                                        will be cancelled forthwith without
                                        compensation or refund in accordance
                                        with the terms and conditions of the
                                        HKJC Priority Programme available at{' '}
                                        <A onClick={toTerms}>
                                            {' '}
                                            Terms and Conditions
                                        </A>
                                        .
                                    </>
                                )}
                            </ClauseText>
                        </ClauseListLi>
                    </ClauseList>
                </InfoWrap>
                <ChexkBoxWrap>
                    <CheckboxInput onClick={() => handleAgree()}>
                        {agree && <CheckedImg src={IconRight} />}
                    </CheckboxInput>
                    <CheckboxLabel>{t('optout.checkbox')}</CheckboxLabel>
                </ChexkBoxWrap>
                <ButtonWrap>
                    <PermissionShow name="opt-out-success">
                        <Button disabled={!agree} onClick={() => optout()}>
                            {t('optout.buttonText')}
                        </Button>
                    </PermissionShow>
                </ButtonWrap>
            </Container>
        </OptOutContainer>
    )
}

const Container = styled.div`
    width: 100%;
    /* background: #1753a8; */
    max-height: calc(100vh - 134rem);
    padding: 10rem;
    padding-top: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media ${device.tablet} {
        padding: 10px;
        padding-top: 0;
        max-height: calc(100vh - 218px);
    }
`

const InfoWrap = styled.div`
    width: 100%;
    border-radius: 10rem;
    padding: 20rem;
    background-color: #fff;
    overflow-y: auto;
    font-weight: 400;
    @media ${device.tablet} {
        border-radius: 20px;
        padding: 20px;
        width: 82%;
        border-radius: 0;
    }
`

const ClauseList = styled.div`
    margin-bottom: 10rem;
`

const ClauseListLi = styled.div`
    display: flex;
    font-size: 14rem;
    font-weight: 400;
    font-weight: 400;
    @media ${device.tablet} {
        font-size: 14px;
    }
`

const ClauseText = styled.div``

const ChexkBoxWrap = styled.div`
    display: flex;
    margin-top: 10rem;
    @media ${device.tablet} {
        width: 82%;
        margin-top: 10px;
    }
`

const CheckboxInput = styled.div`
    position: relative;
    border: 2rem solid #d2d2d2;
    border-radius: 4rem;
    width: 18rem;
    height: 18rem;
    flex-shrink: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    @media ${device.tablet} {
        width: 22px;
        height: 22px;
        border: 2px solid #d2d2d2;
        border-radius: 4px;
    }
`

const CheckedImg = styled.img`
    width: 12rem;
    height: 12rem;
    @media ${device.tablet} {
        width: 16px;
        height: 16px;
    }
`

const CheckboxLabel = styled.div`
    line-height: 21rem;
    padding-left: 6rem;
    line-height: 18rem;
    font-size: 13rem;
    font-weight: 400;
    color: #222;
    @media ${device.tablet} {
        font-size: 14px;
        line-height: 21px;
        padding-left: 6px;
        line-height: 18px;
        font-weight: 400;
    }
`

const ButtonWrap = styled.div`
    display: flex;
    justify-content: center;
    width: 276rem;
    @media ${device.tablet} {
        padding-bottom: 20px;
        width: 476px;
    }
`

const A = styled.a`
    font-size: 14rem;
    font-weight: 400;
    text-decoration: underline;
    @media ${device.tablet} {
        font-size: 14px;
    }
`

export default OptOut
