const size = {
    tablet: '640px',
    laptop: '1024px',
}

export const device = {
    tablet: `(min-width:${size.tablet})`,
    laptop: `(min-width:${size.laptop})`,
}

const handleFontStyle = (param, number) => {
    const cloneParam = JSON.parse(JSON.stringify(param))
    Object.keys(cloneParam).forEach((key) => {
        Object.keys(cloneParam[key]).forEach((deepKey) => {
            cloneParam[key][deepKey] = cloneParam[key][deepKey] + number
        })
    })
    return cloneParam
}

export const getFontSize = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const fontSize = urlParams.get('fontSize')
    const initMobileFontStyle = {
        h0: {
            fontSize: 24,
            lineHeight: 19,
        },
        h1: {
            fontSize: 21,
            lineHeight: 26,
        },
        h2: {
            fontSize: 18,
            lineHeight: 23,
        },
        label1: {
            fontSize: 16,
            lineHeight: 19,
        },
        label2: {
            fontSize: 13,
            lineHeight: 16,
        },
        label3: {
            fontSize: 11,
            lineHeight: 14,
        },
        caption2: 9,
        title1: {
            fontSize: 18,
            lineHeight: 23,
        },
        title2: {
            fontSize: 16,
            lineHeight: 21,
        },
        title3: {
            fontSize: 13,
            lineHeight: 18,
        },
        body2: {
            fontSize: 13,
            lineHeight: 18,
        },
    }

    const initTabletFontStyle = {
        h1: {
            fontSize: 24,
            lineHeight: 29,
        },
        h2: {
            fontSize: 20,
            lineHeight: 25,
        },
        title1: {
            fontSize: 20,
            lineHeight: 25,
        },
        title2: {
            fontSize: 18,
            lineHeight: 23,
        },
        title3: {
            fontSize: 14,
            lineHeight: 19,
        },
        label1: {
            fontSize: 18,
            lineHeight: 22,
        },
        label2: {
            fontSize: 14,
            lineHeight: 17,
        },
        label3: {
            fontSize: 12,
            lineHeight: 15,
        },
        body2: {
            fontSize: 14,
            lineHeight: 19,
        },
    }
    switch (fontSize) {
        case 'L':
            return {
                mobileFontStyle: handleFontStyle(initMobileFontStyle, 2),
                tabletFontStyle: handleFontStyle(initTabletFontStyle, 2),
            }
        case 'XL':
            return {
                mobileFontStyle: handleFontStyle(initMobileFontStyle, 4),
                tabletFontStyle: handleFontStyle(initTabletFontStyle, 4),
            }
        default:
            return {
                mobileFontStyle: initMobileFontStyle,
                tabletFontStyle: initTabletFontStyle,
            }
    }
}
