import React from 'react'
import { useTranslation } from 'react-i18next'
import { device } from 'style/devices'
import styled from 'styled-components'
import bridge from 'utils/mobileJSBridge.js'

const PriorityRegistrationDeclareComponent = () => {
    const { t, i18n } = useTranslation()
    const currentLanguage = i18n.language
    const openBroswer = (url) => {
        bridge.openExternalBrowser(url)
    }
    return (
        <PriorityRegistrationDeclare>
            <PriorityRegistrationDeclareTitle>
                {t('optout.hkjc')}
            </PriorityRegistrationDeclareTitle>
            <PriorityRegistrationDeclareTitle>
                {t('PriorityRegistration.terms')}
            </PriorityRegistrationDeclareTitle>
            <PriorityRegistrationDeclareInfo>
                <PriorityRegistrationDeclareTitle>
                    {t('term.condionsFirstTitle')}
                </PriorityRegistrationDeclareTitle>
                <Ul>
                    <Li>
                        <Index>1.1</Index>
                        <Text>{t('term.condionsFirstText1')}</Text>
                    </Li>
                    <Li>
                        <Index>1.2</Index>
                        <Text>{t('term.condionsFirstText2')}</Text>
                    </Li>
                    <Li>
                        <Index>1.3</Index>
                        <Text>
                            {t('term.condionsFirstText3Before')}
                            <A
                                onClick={() =>
                                    openBroswer(
                                        'https://www.priority.hkjc.com/#/home'
                                    )
                                }
                            >
                                {t('term.condionsFirstText3Link')}
                            </A>
                            {t('term.condionsFirstText3After')}
                        </Text>
                    </Li>
                    <Li>
                        <Index>1.4</Index>
                        <Text>{t('term.condionsFirstText4')}</Text>
                    </Li>
                </Ul>
            </PriorityRegistrationDeclareInfo>
            <PriorityRegistrationDeclareInfo>
                <PriorityRegistrationDeclareTitle>
                    {t('term.condionsSecondTitle')}
                </PriorityRegistrationDeclareTitle>
                <Ul>
                    <Li>
                        <Index>2.1</Index>
                        <Text>
                            {t('term.condionsSecondText1Before')}
                            <A
                                onClick={() =>
                                    openBroswer(
                                        currentLanguage === 'en-US'
                                            ? 'https://www.hkjc.com/home/english/corporate/corp_privacy.aspx'
                                            : 'https://www.hkjc.com/home/chinese/corporate/corp_privacy.aspx'
                                    )
                                }
                            >
                                {t('term.condionsSecondText1Link')}
                            </A>
                            {t('term.condionsSecondText1After')}
                        </Text>
                    </Li>
                    <Li>
                        <Index>2.2</Index>
                        <Text>{t('term.condionsSecondText2')}</Text>
                    </Li>
                    <DotUl>
                        {new Array(6)
                            .fill('term.condionsSecondText2info')
                            .map((d, i) => {
                                return (
                                    <Li key={d + i}>
                                        <Dot></Dot>
                                        <DotText>{t(`${d}${i + 1}`)}</DotText>
                                    </Li>
                                )
                            })}
                    </DotUl>
                    <Li>
                        <Index>2.3</Index>
                        <Text>{t('term.condionsSecondText3')}</Text>
                    </Li>
                    <Li>
                        <Index>2.4</Index>
                        <Text>{t('term.condionsSecondText4')}</Text>
                    </Li>
                </Ul>
            </PriorityRegistrationDeclareInfo>
            <PriorityRegistrationDeclareInfo>
                <PriorityRegistrationDeclareTitle>
                    {t('term.condionsThirdTitle')}
                </PriorityRegistrationDeclareTitle>
                <Ul>
                    <Li>
                        <Index>3.1</Index>
                        <Text>{t('term.condionsThirdText1')}</Text>
                    </Li>
                    <Li>
                        <Index>3.2</Index>
                        <Text>{t('term.condionsThirdText2')}</Text>
                    </Li>
                    <Li>
                        <Index>3.3</Index>
                        <Text>{t('term.condionsThirdText3')}</Text>
                    </Li>
                </Ul>
            </PriorityRegistrationDeclareInfo>
            <PriorityRegistrationDeclareInfo>
                <PriorityRegistrationDeclareTitle>
                    {t('term.condionsFourthTitle')}
                </PriorityRegistrationDeclareTitle>
                <Ul>
                    <Li>
                        <Index>4.1</Index>
                        <Text>{t('term.condionsFourthText1')}</Text>
                    </Li>
                    <Li>
                        <Index>4.2</Index>
                        <Text>{t('term.condionsFourthText2')}</Text>
                    </Li>
                    <Li>
                        <Index>4.3</Index>
                        <Text>{t('term.condionsFourthText3')}</Text>
                    </Li>
                    <Li>
                        <Index>4.4</Index>
                        <Text>{t('term.condionsFourthText4')}</Text>
                    </Li>
                </Ul>
            </PriorityRegistrationDeclareInfo>
            <PriorityRegistrationDeclareInfo>
                <PriorityRegistrationDeclareTitle>
                    {t('term.condionsFifthTitle')}
                </PriorityRegistrationDeclareTitle>
                <Ul>
                    <Li>
                        <Index>5.1</Index>
                        <Text>{t('term.condionsFifthText1')}</Text>
                    </Li>
                    <Li>
                        <Index>5.2</Index>
                        <Text>{t('term.condionsFifthText2')}</Text>
                    </Li>
                    <Li>
                        <Index>5.3</Index>
                        <Text>{t('term.condionsFifthText3')}</Text>
                    </Li>
                </Ul>
            </PriorityRegistrationDeclareInfo>
            <PriorityRegistrationDeclareInfo>
                <PriorityRegistrationDeclareTitle>
                    {t('term.condionsSixthTitle')}
                </PriorityRegistrationDeclareTitle>
                <Ul>
                    <Li>
                        <Index>6.1</Index>
                        <Text>{t('term.condionsSixthText1')}</Text>
                    </Li>
                    <Li>
                        <Index>6.2</Index>
                        <Text>{t('term.condionsSixthText2')}</Text>
                    </Li>
                    <Text>{t('term.statusRequirement')}</Text>
                    <Ul>
                        <DotText>{t('term.priorityBlack')}</DotText>
                        <DotText>{t('term.priorityGold')}</DotText>
                        <DotText>{t('term.prioritySilver')}</DotText>
                        <DotText>{t('term.priorityRed')}</DotText>
                        <DotText>{t('term.priorityYellow')}</DotText>
                    </Ul>
                </Ul>
            </PriorityRegistrationDeclareInfo>
            <PriorityRegistrationDeclareInfo>
                <PriorityRegistrationDeclareTitle>
                    {t('term.condionsSeventhTitle')}
                </PriorityRegistrationDeclareTitle>
                <Ul>
                    <Li>
                        <Index>7.1</Index>
                        <Text>{t('term.condionsSeventhText1')}</Text>
                    </Li>
                    <Li>
                        <Index>7.2</Index>
                        <Text>{t('term.condionsSeventhText2')}</Text>
                    </Li>
                </Ul>
            </PriorityRegistrationDeclareInfo>
            <PriorityRegistrationDeclareInfo>
                <PriorityRegistrationDeclareTitle>
                    {t('term.condionsEighthTitle')}
                </PriorityRegistrationDeclareTitle>
                <Ul>
                    {new Array(5)
                        .fill('term.condionsEighthText')
                        .map((d, i) => (
                            <Li key={i}>
                                <Index>{`8.${i + 1}`}</Index>
                                <Text>{t(`${d}${i + 1}`)}</Text>
                            </Li>
                        ))}
                </Ul>
            </PriorityRegistrationDeclareInfo>
            <PriorityRegistrationDeclareInfo>
                <PriorityRegistrationDeclareTitle>
                    {t('term.condionsNinthTitle')}
                </PriorityRegistrationDeclareTitle>
                <Ul>
                    {new Array(6).fill('term.condionsNinthText').map((d, i) => (
                        <Li key={i}>
                            <Index>{`9.${i + 1}`}</Index>
                            <Text>{t(`${d}${i + 1}`)}</Text>
                        </Li>
                    ))}
                    <PriorityRegistrationDeclareTitle>
                        {t('term.condionsNinthSpendings')}
                    </PriorityRegistrationDeclareTitle>

                    <SubTitle>{t('term.condionsNinthBetting1')}</SubTitle>
                    <Ul>
                        <Li>
                            <Dot></Dot>
                            <DotText>{t('term.condionsNinthDot1')}</DotText>
                        </Li>
                        <Li>
                            <Dot></Dot>
                            <DotText>{t('term.condionsNinthDot2')}</DotText>
                        </Li>
                    </Ul>
                    <SubTitle>{t('term.condionsNinthBetting2')}</SubTitle>
                    <Ul>
                        <Li>
                            <Dot></Dot>
                            <DotText>{t('term.condionsNinthDot3')}</DotText>
                        </Li>
                        <Li>
                            <Dot></Dot>
                            <DotText>{t('term.condionsNinthDot4')}</DotText>
                        </Li>
                    </Ul>
                    <SubTitle>{t('term.condionsNinthBetting3')}</SubTitle>
                    <Ul>
                        <Li>
                            <Dot></Dot>
                            <DotText>{t('term.condionsNinthDot5')}</DotText>
                        </Li>
                    </Ul>
                    <SubTitle>{t('term.condionsNinthBetting4')}</SubTitle>
                    <Ul>
                        <Li>
                            <Dot></Dot>
                            <DotText>{t('term.condionsNinthDot6')}</DotText>
                        </Li>
                    </Ul>
                    <SubTitle>{t('term.condionsNinthBetting5')}</SubTitle>
                    <Ul>
                        <Li>
                            <Dot></Dot>
                            <DotText>{t('term.condionsNinthDot7')}</DotText>
                        </Li>
                    </Ul>
                    <SubTitle>{t('term.condionsNinthBetting6')}</SubTitle>
                    <Ul>
                        <Li>
                            <Dot></Dot>
                            <DotText>{t('term.condionsNinthDot8')}</DotText>
                        </Li>
                    </Ul>
                    {new Array(5).fill('term.condionsNinthText').map((d, i) => (
                        <Li key={i}>
                            <Index>{`9.${i + 7}`}</Index>
                            <Text>{t(`${d}${i + 7}`)}</Text>
                        </Li>
                    ))}
                </Ul>
            </PriorityRegistrationDeclareInfo>
            <PriorityRegistrationDeclareInfo>
                <PriorityRegistrationDeclareTitle>
                    {t('term.condionsTenthTitle')}
                </PriorityRegistrationDeclareTitle>
                <Ul>
                    {new Array(11)
                        .fill('term.condionsTenthText')
                        .map((d, i) => (
                            <Li key={i}>
                                <Index>{`10.${i + 1}`}</Index>
                                <Text>{t(`${d}${i + 1}`)}</Text>
                            </Li>
                        ))}
                </Ul>
            </PriorityRegistrationDeclareInfo>
            <PriorityRegistrationDeclareInfo>
                <PriorityRegistrationDeclareTitle>
                    {t('term.condionsEleventhTitle')}
                </PriorityRegistrationDeclareTitle>
                <Ul>
                    {new Array(1)
                        .fill('term.condionsEleventhText')
                        .map((d, i) => (
                            <Li key={i}>
                                <Index>{`11.${i + 1}`}</Index>
                                <Text>{t(`${d}${i + 1}`)}</Text>
                            </Li>
                        ))}
                </Ul>
                <Ul>
                    <PriorityRegistrationDeclareTitle>
                        {t('term.Outlets')}
                    </PriorityRegistrationDeclareTitle>
                    <Ul>
                        <SubTitle>{t('term.Racecourse1')}</SubTitle>
                        <Text>{t('term.RacecourseInfo1')}</Text>
                        <SubTitle>{t('term.Racecourse2')}</SubTitle>
                        <Text>{t('term.RacecourseInfo2')}</Text>
                        <SubTitle>{t('term.Racecourse3')}</SubTitle>
                        <Text>{t('term.RacecourseInfo3')}</Text>
                        <SubTitle></SubTitle>
                        <Text>{t('term.outletsTips')}</Text>
                    </Ul>
                </Ul>
            </PriorityRegistrationDeclareInfo>
            <PriorityRegistrationDeclareInfo>
                <PriorityRegistrationDeclareTitle>
                    {t('term.condionsTwelfthTitle')}
                </PriorityRegistrationDeclareTitle>
                <Ul>
                    {new Array(4)
                        .fill('term.condionsTwelfthText')
                        .map((d, i) => (
                            <Li key={i}>
                                <Index>{`12.${i + 1}`}</Index>
                                <Text>{t(`${d}${i + 1}`)}</Text>
                            </Li>
                        ))}
                </Ul>
            </PriorityRegistrationDeclareInfo>
            <PriorityRegistrationDeclareInfo>
                <PriorityRegistrationDeclareTitle>
                    {t('term.condionsThirteenthTitle')}
                </PriorityRegistrationDeclareTitle>
                <Ul>
                    {new Array(8)
                        .fill('term.condionsThirteenthText')
                        .map((d, i) => (
                            <Li key={i}>
                                <Index>{`13.${i + 1}`}</Index>
                                <Text>{t(`${d}${i + 1}`)}</Text>
                            </Li>
                        ))}
                </Ul>
            </PriorityRegistrationDeclareInfo>
            <PriorityRegistrationDeclareInfo>
                <PriorityRegistrationDeclareTitle>
                    {t('term.condionsFourteenthTitle')}
                </PriorityRegistrationDeclareTitle>
                <Ul>
                    {new Array(9)
                        .fill('term.condionsFourteenthText')
                        .map((d, i) => (
                            <Li key={i}>
                                <Index>{`14.${i + 1}`}</Index>
                                <Text>{t(`${d}${i + 1}`)}</Text>
                            </Li>
                        ))}
                </Ul>
            </PriorityRegistrationDeclareInfo>
            <PriorityRegistrationDeclareInfo>
                <PriorityRegistrationDeclareTitle>
                    {t('term.condionsFifteenthTitle')}
                </PriorityRegistrationDeclareTitle>
                <Ul>
                    <Li>
                        <Index>15.1</Index>
                        <Text>
                            {t('term.condionsFifteenthText1Before')}

                            <A
                                onClick={() =>
                                    openBroswer(
                                        currentLanguage === 'en-US'
                                            ? 'https://www.hkjc.com/home/english/corporate/corp_privacy.aspx'
                                            : 'https://www.hkjc.com/home/chinese/corporate/corp_privacy.aspx'
                                    )
                                }
                            >
                                {t('term.condionsFifteenthText1Link')}
                            </A>
                            {t('term.condionsFifteenthText1After')}
                        </Text>
                    </Li>
                    <Li>
                        <Index>15.2</Index>
                        <Text>{t('term.condionsFifteenthText2')}</Text>
                    </Li>
                </Ul>
            </PriorityRegistrationDeclareInfo>
        </PriorityRegistrationDeclare>
    )
}

const PriorityRegistrationDeclare = styled.div`
    font-size: 13rem;
    font-weight: 400;
    line-height: 18rem;
    letter-spacing: 0rem;
    text-align: left;
    overflow: hidden;
    /* padding-bottom: 10rem; */
    @media ${device.tablet} {
        font-size: 18px;
        border-top: 1px solid #e0e0e0;
        line-height: 21px;
    }
`

const PriorityRegistrationDeclareTitle = styled.div`
    font-size: 16rem;
    font-weight: 700;
    line-height: 21rem;
    letter-spacing: 0em;
    text-align: left;
    @media ${device.tablet} {
        line-height: 30px;
        font-size: 18px;
    }
`

const PriorityRegistrationDeclareInfo = styled.div`
    font-size: 13rem;
    margin-top: 8rem;
    @media ${device.tablet} {
        font-size: 16px;
        margin-top: 8px;
    }
`

const Text = styled.div`
    margin-bottom: 8rem;
    @media ${device.tablet} {
        margin-bottom: 12px;
    }
`

const Ul = styled.div`
    padding-left: 8rem;
    margin-top: 8rem;
    width: 100%;
    @media ${device.tablet} {
        margin-bottom: 12px;
        margin-top: 12px;
    }
`

const Li = styled.div`
    display: flex;
`
const Index = styled.div`
    margin-right: 4rem;
    @media ${device.tablet} {
        margin-right: 6px;
    }
`

const A = styled.span`
    font-size: 13rem;
    font-weight: 400;
    text-decoration: underline;
    word-wrap: break-word;
    word-break: break-all;
    @media ${device.tablet} {
        font-size: 16px;
    }
`

const Dot = styled.div`
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background: #000;
    flex-shrink: 0;
    margin-top: 8rem;
    margin-right: 6rem;
    @media ${device.tablet} {
        margin-top: 8px;
        margin-right: 6px;
        width: 4px;
        height: 4px;
    }
`
const DotUl = styled(Ul)`
    padding-left: 22rem;
    margin-top: 0;
    margin-bottom: 8rem;
    @media ${device.tablet} {
        padding-left: 22px;
        margin-bottom: 12px;
    }
`
const DotText = styled.div``

const SubTitle = styled.div`
    margin-top: 8rem;
    font-weight: 500;
    @media ${device.tablet} {
        margin-top: 12px;
    }
`
export default PriorityRegistrationDeclareComponent
