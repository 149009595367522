import Setmeal from 'assets/icons/set-meal.svg'
import UserImg from 'assets/icons/user.svg'
import CakeImg from 'assets/icons/cake.svg'
import CardImg from 'assets/icons/card3.svg'
import UpImg from 'assets/icons/up_icon.png'
import BenefitSvg1 from 'assets/icons/benefit1.svg'
import BenefitSvg2 from 'assets/icons/benefit2.svg'
import BenefitSvg3 from 'assets/icons/benefit3.svg'
import BenefitSvg4 from 'assets/icons/benefit4.svg'
import WinsIcon from 'assets/icons/wins_outline.png'
import Racing from 'assets/icons/racing.svg'

export const PriorityRegistrationApi = () => {
    return [
        {
            title: 'PriorityRegistration.freeRegisterTitle',
            describe: 'PriorityRegistration.freeRegisterDescribe',
            img: UserImg,
        },
        {
            title: 'PriorityRegistration.experienceTitle',
            img: CakeImg,
        },
        {
            title: 'PriorityRegistration.priorityTitle',
            describe: 'PriorityRegistration.priorityDescribe',
            img: Setmeal,
        },
        {
            title: 'PriorityRegistration.rewardsTitle',
            describe: 'PriorityRegistration.rewardsDescribe',
            img: CardImg,
        },
        {
            title: 'PriorityRegistration.red',
            describe: 'PriorityRegistration.redDescribe',
            img: UpImg,
        },
    ]
}

export const PCardNumberApi = () => {
    return [
        {
            title: 'PriorityRegistration.accumulate',
            img: CakeImg,
        },
        {
            title: 'PriorityRegistration.redeem',
            img: Setmeal,
        },
        {
            title: 'PriorityRegistration.extend',
            img: Racing,
        },
    ]
}

export const tierBenefitRemarks = [
    {
        text: 'tierBenefit.remark1',
    },

    {
        text: 'tierBenefit.remark2',
    },
    {
        text: 'tierBenefit.remark3',
    },
    {
        text: 'tierBenefit.remark4',
    },
]

export const tierBenefitOmtRemarks = [
    {
        text: 'tierBenefit.remarkOmt1',
    },

    {
        text: 'tierBenefit.remarkOmt2',
    },
]

export const AboutList = [
    {
        title: 'about.title',
        sections: [
            {
                text: 'about.infoSection1',
            },
            {
                text: 'about.infoSection2',
            },
            {
                text: 'about.infoSection3',
            },
        ],
    },
    {
        title: 'about.points',
        textList: [
            {
                text: 'about.pointsSection1',
            },
            {
                text: 'about.pointsSection2',
            },
            {
                text: 'about.pointsSection3',
            },
        ],
    },
    {
        title: 'about.way',
        textList: [
            {
                text: 'about.wayList1',
            },
            {
                text: 'about.wayList2',
            },
            {
                text: 'about.wayList3',
            },
            {
                text: 'about.wayList4',
            },
            {
                text: 'about.wayList5',
            },
        ],
    },
]

export const myPrivileges = [
    {
        imgUrl: WinsIcon,
        serviceType: 'tierBenefit.priorityTitle',
        Yellow: {
            lockPrivileges: [
                {
                    title: 'tierBenefit.WINS',
                },
                {
                    text: 'tierBenefit.priorityTimeEnd',
                },
            ],
        },
        Red: {
            lockPrivileges: [
                {
                    title: 'tierBenefit.WINS',
                },
                {
                    text: 'tierBenefit.priorityTimeEnd',
                },
            ],
        },
        Silver: {
            lockPrivileges: [
                {
                    title: 'tierBenefit.WINS',
                },
                {
                    text: 'tierBenefit.priorityTimeEnd',
                },
            ],
        },
        Gold: {
            lockPrivileges: [
                {
                    title: 'tierBenefit.WINS',
                },
                {
                    text: 'tierBenefit.priorityTimeEnd',
                },
            ],
        },
        Black: {
            lockPrivileges: [
                {
                    title: 'tierBenefit.WINS',
                },
                {
                    text: 'tierBenefit.priorityTimeEnd',
                },
            ],
        },
    },
    {
        imgUrl: BenefitSvg1,
        serviceType: 'tierBenefit.servicesTitle',
        Yellow: {
            lockPrivileges: [],
            unLockPrivileges: [
                {
                    text: 'tierBenefit.yellowServicesUnlock3',
                },
                {
                    text: 'tierBenefit.yellowServicesUnlock1',
                },
                {
                    text: 'tierBenefit.yellowServicesUnlock2',
                },

                {
                    text: 'tierBenefit.yellowServicesUnlock4',
                },
            ],
        },
        Red: {
            lockPrivileges: [
                {
                    text: 'tierBenefit.yellowServicesUnlock3',
                },
                {
                    text: 'tierBenefit.yellowServicesUnlock1',
                },
                {
                    text: 'tierBenefit.yellowServicesUnlock2',
                },

                {
                    text: 'tierBenefit.yellowServicesUnlock4',
                },
            ],
        },
        Silver: {
            lockPrivileges: [
                {
                    text: 'tierBenefit.yellowServicesUnlock3',
                },
                {
                    text: 'tierBenefit.yellowServicesUnlock1',
                },
                {
                    text: 'tierBenefit.yellowServicesUnlock2',
                },

                {
                    text: 'tierBenefit.yellowServicesUnlock4',
                },
            ],
            unLockPrivileges: [
                {
                    text: 'tierBenefit.silverExperience1',
                },
            ],
        },
        Gold: {
            lockPrivileges: [
                {
                    text: 'tierBenefit.yellowServicesUnlock3',
                },
                {
                    text: 'tierBenefit.yellowServicesUnlock1',
                },
                {
                    text: 'tierBenefit.yellowServicesUnlock2',
                },

                {
                    text: 'tierBenefit.yellowServicesUnlock4',
                },
                {
                    text: 'tierBenefit.silverExperience1',
                },
            ],
            unLockPrivileges: [{ text: 'tierBenefit.goldServicesUnlock1' }],
        },
        Black: {
            lockPrivileges: [
                {
                    text: 'tierBenefit.yellowServicesUnlock3',
                },
                {
                    text: 'tierBenefit.yellowServicesUnlock1',
                },
                {
                    text: 'tierBenefit.yellowServicesUnlock2',
                },

                {
                    text: 'tierBenefit.yellowServicesUnlock4',
                },
                {
                    text: 'tierBenefit.silverExperience1',
                },
                { text: 'tierBenefit.goldServicesUnlock1' },
            ],
        },
    },
    {
        imgUrl: BenefitSvg2,
        serviceType: 'tierBenefit.experienceTitle',
        Yellow: {
            lockPrivileges: [
                {
                    text: 'tierBenefit.redExperience1',
                },
            ],
            unLockPrivileges: [
                {
                    text: 'tierBenefit.yellowExperienceUnlock2',
                },
                {
                    text: 'tierBenefit.yellowExperienceUnlock3',
                },
                {
                    text: 'tierBenefit.yellowExperienceUnlock4',
                },
            ],
        },
        Red: {
            lockPrivileges: [
                {
                    text: 'tierBenefit.redExperience1',
                },

                {
                    text: 'tierBenefit.yellowExperienceUnlock2',
                },
                {
                    text: 'tierBenefit.yellowExperienceUnlock3',
                },
                {
                    text: 'tierBenefit.yellowExperienceUnlock4',
                },
            ],
            unLockPrivileges: [
                {
                    text: 'tierBenefit.redExperienceUnlock1',
                },
                {
                    text: 'tierBenefit.redExperienceUnlock2',
                },
            ],
        },
        Silver: {
            lockPrivileges: [
                {
                    text: 'tierBenefit.redExperience1',
                },

                {
                    text: 'tierBenefit.yellowExperienceUnlock2',
                },
                {
                    text: 'tierBenefit.yellowExperienceUnlock3',
                },
                {
                    text: 'tierBenefit.yellowExperienceUnlock4',
                },
                {
                    text: 'tierBenefit.redExperienceUnlock1',
                },
                {
                    text: 'tierBenefit.redExperienceUnlock2',
                },
            ],
            unLockPrivileges: [
                {
                    text: 'tierBenefit.silverExperienceUnlock2',
                },
            ],
        },
        Gold: {
            lockPrivileges: [
                {
                    text: 'tierBenefit.redExperience1',
                },

                {
                    text: 'tierBenefit.yellowExperienceUnlock2',
                },
                {
                    text: 'tierBenefit.yellowExperienceUnlock3',
                },
                {
                    text: 'tierBenefit.yellowExperienceUnlock4',
                },
                {
                    text: 'tierBenefit.redExperienceUnlock1',
                },
                {
                    text: 'tierBenefit.redExperienceUnlock2',
                },
                {
                    text: 'tierBenefit.silverExperienceUnlock2',
                },
            ],
            unLockPrivileges: [
                {
                    text: 'tierBenefit.silverExperienceUnlock1',
                },
                {
                    text: 'tierBenefit.goldExperienceUnlock1',
                },
            ],
        },
        Black: {
            lockPrivileges: [
                {
                    text: 'tierBenefit.redExperience1',
                },

                {
                    text: 'tierBenefit.yellowExperienceUnlock2',
                },
                {
                    text: 'tierBenefit.yellowExperienceUnlock3',
                },
                {
                    text: 'tierBenefit.yellowExperienceUnlock4',
                },
                {
                    text: 'tierBenefit.redExperienceUnlock1',
                },
                {
                    text: 'tierBenefit.redExperienceUnlock2',
                },
                {
                    text: 'tierBenefit.silverExperienceUnlock1',
                },
                {
                    text: 'tierBenefit.silverExperienceUnlock2',
                },
                {
                    text: 'tierBenefit.goldExperienceUnlock1',
                },
            ],
        },
    },
    {
        imgUrl: BenefitSvg3,
        serviceType: 'tierBenefit.essentialTitle',
        Yellow: {
            lockPrivileges: [],
            unLockPrivileges: [
                {
                    text: 'tierBenefit.yellowEssentialUnlock1',
                },
                {
                    text: 'tierBenefit.yellowEssentialUnlock2',
                },
            ],
        },
        Red: {
            lockPrivileges: [
                { text: 'tierBenefit.yellowEssentialUnlock1' },
                { text: 'tierBenefit.yellowEssentialUnlock2' },
            ],
        },
        Silver: {
            lockPrivileges: [
                { text: 'tierBenefit.yellowEssentialUnlock1' },
                { text: 'tierBenefit.yellowEssentialUnlock2' },
            ],
            unLockPrivileges: [
                { text: 'tierBenefit.silverEssentialUnlock1' },
                { text: 'tierBenefit.silverEssentialUnlock2' },
            ],
        },
        Gold: {
            lockPrivileges: [
                { text: 'tierBenefit.yellowEssentialUnlock1' },
                { text: 'tierBenefit.yellowEssentialUnlock2' },
                { text: 'tierBenefit.silverEssentialUnlock1' },
                { text: 'tierBenefit.silverEssentialUnlock2' },
            ],
        },
        Black: {
            lockPrivileges: [
                { text: 'tierBenefit.yellowEssentialUnlock1' },
                { text: 'tierBenefit.yellowEssentialUnlock2' },
                { text: 'tierBenefit.silverEssentialUnlock1' },
                { text: 'tierBenefit.silverEssentialUnlock2' },
            ],
        },
    },
    {
        imgUrl: BenefitSvg4,
        serviceType: 'tierBenefit.exclusiveTitle',
        Yellow: {
            lockPrivileges: [],
            unLockPrivileges: [
                {
                    text: 'tierBenefit.yellowExclusiveUnlock1',
                },
            ],
        },
        Red: {
            lockPrivileges: [{ text: 'tierBenefit.yellowExclusiveUnlock1' }],
            unLockPrivileges: [
                {
                    text: 'tierBenefit.redExclusiveUnlock1',
                },
            ],
        },
        Silver: {
            lockPrivileges: [{ text: 'tierBenefit.redExclusiveUnlock1' }],
            unLockPrivileges: [
                {
                    text: 'tierBenefit.silverExclusivelUnlock1',
                },
                {
                    text: 'tierBenefit.silverExclusivelUnlock2',
                },
                {
                    text: 'tierBenefit.silverExclusivelUnlock3',
                },
                {
                    text: 'tierBenefit.redExclusiveUnlock2',
                },
            ],
        },
        Gold: {
            lockPrivileges: [
                { text: 'tierBenefit.silverExclusivelUnlock1' },
                { text: 'tierBenefit.redExclusiveUnlock2' },
                { text: 'tierBenefit.silverExclusivelUnlock2' },
                { text: 'tierBenefit.silverExclusivelUnlock3' },
            ],
            unLockPrivileges: [
                { text: 'tierBenefit.goldExclusivelUnlock1' },
                { text: 'tierBenefit.goldExclusivelUnlock2' },
                { text: 'tierBenefit.goldExclusivelUnlock3' },
                { text: 'tierBenefit.goldExclusivelUnlock4' },
                { text: 'tierBenefit.goldExclusivelUnlock5' },
            ],
        },
        Black: {
            lockPrivileges: [
                { text: 'tierBenefit.goldExclusivelUnlock1' },
                { text: 'tierBenefit.redExclusiveUnlock2' },
                { text: 'tierBenefit.silverExclusivelUnlock2' },
                { text: 'tierBenefit.silverExclusivelUnlock3' },
                { text: 'tierBenefit.goldExclusivelUnlock2' },
                { text: 'tierBenefit.goldExclusivelUnlock3' },
                { text: 'tierBenefit.goldExclusivelUnlock4' },
                { text: 'tierBenefit.goldExclusivelUnlock5' },
            ],
        },
    },
]

export const myOMTPrivileges = [
    {
        imgUrl: WinsIcon,
        serviceType: 'tierBenefit.priorityTitle',
        Yellow: {
            lockPrivileges: [
                {
                    title: 'tierBenefit.WINS',
                },
                {
                    text: 'tierBenefit.priorityTimeEnd',
                },
            ],
        },
        Red: {
            lockPrivileges: [
                {
                    title: 'tierBenefit.WINS',
                },
                {
                    text: 'tierBenefit.priorityTimeEnd',
                },
            ],
        },
        Silver: {
            lockPrivileges: [
                {
                    title: 'tierBenefit.WINS',
                },
                {
                    text: 'tierBenefit.priorityTimeEnd',
                },
            ],
        },
        Gold: {
            lockPrivileges: [
                {
                    title: 'tierBenefit.WINS',
                },
                {
                    text: 'tierBenefit.priorityTimeEnd',
                },
            ],
        },
        Black: {
            lockPrivileges: [
                {
                    title: 'tierBenefit.WINS',
                },
                {
                    text: 'tierBenefit.priorityTimeEnd',
                },
            ],
        },
    },
    {
        imgUrl: BenefitSvg1,
        serviceType: 'tierBenefit.servicesTitle',
        Yellow: {
            lockPrivileges: [],
            unLockPrivileges: [
                {
                    text: 'tierBenefit.yellowServicesUnlock3',
                },
                {
                    text: 'tierBenefit.yellowServicesUnlock1',
                },
                {
                    text: 'tierBenefit.yellowOmtServicesUnlock2',
                },

                {
                    text: 'tierBenefit.yellowServicesUnlock4',
                },
            ],
        },
        Red: {
            lockPrivileges: [
                {
                    text: 'tierBenefit.yellowServicesUnlock3',
                },
                {
                    text: 'tierBenefit.yellowServicesUnlock1',
                },
                {
                    text: 'tierBenefit.yellowOmtServicesUnlock2',
                },

                {
                    text: 'tierBenefit.yellowServicesUnlock4',
                },
            ],
        },
        Silver: {
            lockPrivileges: [
                {
                    text: 'tierBenefit.yellowServicesUnlock3',
                },
                {
                    text: 'tierBenefit.yellowServicesUnlock1',
                },
                {
                    text: 'tierBenefit.yellowOmtServicesUnlock2',
                },

                {
                    text: 'tierBenefit.yellowServicesUnlock4',
                },
            ],
            unLockPrivileges: [
                {
                    text: 'tierBenefit.silverExperience1',
                },
                {
                    text: 'tierBenefit.silverOmtExperience1',
                },
            ],
        },
        Gold: {
            lockPrivileges: [
                {
                    text: 'tierBenefit.yellowServicesUnlock3',
                },
                {
                    text: 'tierBenefit.yellowServicesUnlock1',
                },
                {
                    text: 'tierBenefit.yellowOmtServicesUnlock2',
                },

                {
                    text: 'tierBenefit.yellowServicesUnlock4',
                },
                {
                    text: 'tierBenefit.silverExperience1',
                },
                { text: 'tierBenefit.silverOmtExperience1' },
            ],
        },
        Black: {
            lockPrivileges: [
                {
                    text: 'tierBenefit.yellowServicesUnlock3',
                },
                {
                    text: 'tierBenefit.yellowServicesUnlock1',
                },
                {
                    text: 'tierBenefit.yellowOmtServicesUnlock2',
                },
                {
                    text: 'tierBenefit.yellowServicesUnlock4',
                },
                {
                    text: 'tierBenefit.silverExperience1',
                },
                { text: 'tierBenefit.goldServicesUnlock1' },
            ],
        },
    },
    {
        imgUrl: BenefitSvg2,
        serviceType: 'tierBenefit.experienceTitle',
        Yellow: {
            lockPrivileges: [],
            unLockPrivileges: [
                {
                    text: 'tierBenefit.yellowOmtExperienceUnlock1',
                },
            ],
        },
        Red: {
            lockPrivileges: [
                {
                    text: 'tierBenefit.yellowOmtExperienceUnlock1',
                },
            ],
            unLockPrivileges: [
                {
                    text: 'tierBenefit.redOmtExperienceUnlock1',
                },
                {
                    text: 'tierBenefit.redExperienceUnlock1',
                },

                {
                    text: 'tierBenefit.redOmtExperienceUnlock2',
                },
            ],
        },
        Silver: {
            lockPrivileges: [
                {
                    text: 'tierBenefit.yellowOmtExperienceUnlock1',
                },
                {
                    text: 'tierBenefit.redOmtExperienceUnlock1',
                },
                {
                    text: 'tierBenefit.redExperienceUnlock1',
                },

                {
                    text: 'tierBenefit.redOmtExperienceUnlock2',
                },
            ],
            unLockPrivileges: [
                {
                    text: 'tierBenefit.silverExperienceUnlock2',
                },
            ],
        },
        Gold: {
            lockPrivileges: [
                {
                    text: 'tierBenefit.yellowOmtExperienceUnlock1',
                },
                {
                    text: 'tierBenefit.redOmtExperienceUnlock1',
                },
                {
                    text: 'tierBenefit.redExperienceUnlock1',
                },

                {
                    text: 'tierBenefit.redOmtExperienceUnlock2',
                },
                {
                    text: 'tierBenefit.silverExperienceUnlock2',
                },
            ],
        },
        Black: {
            lockPrivileges: [
                {
                    text: 'tierBenefit.yellowOmtExperienceUnlock1',
                },
                {
                    text: 'tierBenefit.redOmtExperienceUnlock1',
                },
                {
                    text: 'tierBenefit.redExperienceUnlock1',
                },

                {
                    text: 'tierBenefit.redOmtExperienceUnlock2',
                },
                {
                    text: 'tierBenefit.silverExperienceUnlock2',
                },
            ],
        },
    },
    {
        imgUrl: BenefitSvg3,
        serviceType: 'tierBenefit.essentialTitle',
        Yellow: {
            lockPrivileges: [],
            unLockPrivileges: [
                {
                    text: 'tierBenefit.yellowEssentialUnlock1',
                },
                {
                    text: 'tierBenefit.yellowEssentialUnlock2',
                },
                {
                    text: 'tierBenefit.silverEssentialUnlock1',
                },
            ],
        },
        Red: {
            lockPrivileges: [
                { text: 'tierBenefit.yellowEssentialUnlock1' },
                { text: 'tierBenefit.yellowEssentialUnlock2' },
                { text: 'tierBenefit.yellowOmtEssentialUnlock2' },
            ],
        },
        Silver: {
            lockPrivileges: [
                { text: 'tierBenefit.yellowEssentialUnlock1' },
                { text: 'tierBenefit.yellowEssentialUnlock2' },
                { text: 'tierBenefit.silverEssentialUnlock1' },
            ],
            unLockPrivileges: [{ text: 'tierBenefit.silverEssentialUnlock2' }],
        },
        Gold: {
            lockPrivileges: [
                { text: 'tierBenefit.yellowEssentialUnlock1' },
                { text: 'tierBenefit.yellowEssentialUnlock2' },
                { text: 'tierBenefit.silverEssentialUnlock1' },
                { text: 'tierBenefit.silverEssentialUnlock2' },
            ],
        },
        Black: {
            lockPrivileges: [
                { text: 'tierBenefit.yellowEssentialUnlock1' },
                { text: 'tierBenefit.yellowEssentialUnlock2' },
                { text: 'tierBenefit.silverEssentialUnlock1' },
                { text: 'tierBenefit.silverEssentialUnlock2' },
            ],
        },
    },
    {
        imgUrl: BenefitSvg4,
        serviceType: 'tierBenefit.exclusiveTitle',
        Yellow: {
            lockPrivileges: [],
            unLockPrivileges: [
                {
                    text: 'tierBenefit.yellowExclusiveUnlock1',
                },
            ],
        },
        Red: {
            lockPrivileges: [{ text: 'tierBenefit.yellowExclusiveUnlock1' }],
            unLockPrivileges: [
                {
                    text: 'tierBenefit.redExclusiveUnlock1',
                },
            ],
        },
        Silver: {
            lockPrivileges: [{ text: 'tierBenefit.redExclusiveUnlock1' }],
            unLockPrivileges: [
                {
                    text: 'tierBenefit.silverExclusivelUnlock1',
                },
                {
                    text: 'tierBenefit.silverExclusivelUnlock3',
                },
                {
                    text: 'tierBenefit.redExclusiveUnlock2',
                },
                {
                    text: 'tierBenefit.goldExclusivelUnlock2',
                },
            ],
        },
        Gold: {
            lockPrivileges: [
                { text: 'tierBenefit.silverExclusivelUnlock1' },
                { text: 'tierBenefit.silverExclusivelUnlock3' },
                { text: 'tierBenefit.redExclusiveUnlock2' },
                {
                    text: 'tierBenefit.goldExclusivelUnlock2',
                },
            ],
            unLockPrivileges: [
                { text: 'tierBenefit.goldExclusivelUnlock1' },
                { text: 'tierBenefit.goldExclusivelUnlock3' },
                { text: 'tierBenefit.goldExclusivelUnlock4' },
                { text: 'tierBenefit.goldExclusivelUnlock5' },
            ],
        },
        Black: {
            lockPrivileges: [
                { text: 'tierBenefit.goldExclusivelUnlock1' },
                { text: 'tierBenefit.silverExclusivelUnlock3' },
                { text: 'tierBenefit.redExclusiveUnlock2' },
                {
                    text: 'tierBenefit.goldExclusivelUnlock2',
                },
                { text: 'tierBenefit.goldExclusivelUnlock3' },
                { text: 'tierBenefit.goldExclusivelUnlock4' },
                { text: 'tierBenefit.goldExclusivelUnlock5' },
            ],
        },
    },
]

export const remarkList = ['about.remarkList1', 'about.remarkList2']

export const optOutList = [
    {
        title: 'optout.processTitle',
        text: `optout.processDesc`,
    },
    {
        title: 'optout.deactivationTitle',
        text: `optout.deactivationDesc`,
    },
    // {
    //     title: 'optout.timeTitle',
    //     text: `optout.timeDesc`,
    // },
    // {
    //     title: 'optout.deletioTitle',
    //     text: `optout.deletioDesc`,
    // },
    // {
    //     title: 'optout.dataPrivacy',
    //     text: `optout.dataPrivacyDesc`,
    // },
    // {
    //     title: 'optout.amendments',
    //     text: `optout.amendmentsDesc`,
    // },
    // {
    //     title: 'optout.law',
    //     text: `optout.lawDesc`,
    // },
]
