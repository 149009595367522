const configs = {
    API_MAX_RETRIES: process.env.REACT_APP_API_MAX_RETRIES,
    IMG_URL: process.env.REACT_APP_IMG_URL,
    MVP_API: process.env.REACT_APP_MVP_API,
    LP_API: process.env.REACT_APP_LP_API,
    CMS_API: process.env.REACT_APP_CMS_API,
    MEMBER_PROFILE: process.env.REACT_APP_MEMBER_PROFILE,
    MEMBER_TRANSACTIONS: process.env.REACT_APP_MEMBER_TRANSACTIONS,
    CWL_ADDRESS: process.env.REACT_APP_CWL_ADDRESS,
    LOGIN: process.env.REACT_APP_LOGIN,
    DIRECT_MARKETING_FLAP: process.env.REACT_APP_DIRECT_MARKETING_FLAP,
    ACCOUNT_VALIDATION: process.env.REACT_APP_ACCOUNT_VALIDATION,
    PAYMENT_ENCRYPT: process.env.REACT_APP_PAYMENT_ENCRYPT,
    CROMAPPING: process.env.REACT_APP_CROMAPPING,
}
/**
 *  375/834 = 0.45
 *  375- vw use this value to caculate px-vw
 *  834- pad portrait screen width
 */
const TabletProtraitScale = 0.45
/**
 * same as above,
 * 375/1194 = 0.31
 */
const TabletLandscapeScale = 0.31
export { TabletProtraitScale, TabletLandscapeScale }

export default configs
