import defaultImg from 'assets/banner/rewardPage/img1.png'
import ImgMask from 'assets/icons/Mask_reward_listing.png'
import styled from 'styled-components'

const ImgShadow = styled.div`
    width: 100%;
    position: relative;
    display: inline-block;
    height: fit-content;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(${ImgMask}) no-repeat center bottom;
        background-size: cover;
        pointer-events: none;
    }
`
const ImgBox = styled.img`
    vertical-align: bottom;
`

const ImageWithError = ({ src, className }) => {
    const onImgLoadError = (e) => {
        e.target.src = defaultImg
        e.target.onError = null
    }

    return (
        <ImgShadow className={className}>
            <ImgBox className={className} src={src} onError={onImgLoadError} />
        </ImgShadow>
    )
}

export default ImageWithError
