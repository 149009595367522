import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { device } from 'style/devices'
import FaqArrow from 'assets/icons/faq-arrow.svg'

const FaqCollapse = (props) => {
    const { title, index = 0, children, toBottom } = props
    const [expand, setExpand] = useState()
    const handleExpand = () => {
        setExpand(!expand)
    }

    useEffect(() => {
        if (expand) {
            toBottom?.()
        }
    }, [expand])
    return (
        <CardBox>
            <Number>{index + 1}</Number>
            <Header $expand={expand} onClick={handleExpand}>
                <Title>{title}</Title>
                <Arrow src={FaqArrow} $expand={expand}></Arrow>
            </Header>
            <Content $expand={expand}>{children}</Content>
        </CardBox>
    )
}

const CardBox = styled.div`
    position: relative;
    border-radius: 8px;
    @media ${device.tablet} {
        border-radius: 8px;
    }
`

const Header = styled.div`
    background: #fff;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 16rem 12rem 16rem 18rem;
    border-radius: ${(props) => (props.$expand ? '8px 8px 0 0' : '8px')};
    @media ${device.tablet} {
        padding: 16px 12px 16px 18px;
        border-radius: ${(props) => (props.$expand ? '8px 8px 0 0' : '8px')};
    }
`

const Title = styled.div`
    width: 90%;
    font-weight: 700;
    font-size: 14rem;
    line-height: 20rem;
    color: #222;
    @media ${device.tablet} {
        font-size: 16px;
        line-height: 22px;
    }
`

const Number = styled.div`
    width: 24rem;
    height: 24rem;
    position: absolute;
    left: -12rem;
    background: #d2d2d2;
    text-align: center;
    /* box-shadow: 0px 4px 14px 0px #00000026; */

    color: #222;
    line-height: 24rem;
    margin-top: 8rem;
    border-radius: 50%;
    font-size: 14rem;
    font-weight: 400;
    @media ${device.tablet} {
        width: 24px;
        height: 24px;
        left: -12px;
        line-height: 24px;
        margin-top: 8px;
        font-size: 14px;
    }
`

const Arrow = styled.img`
    display: block;
    transition: all 0.3s;
    transform: ${(props) =>
        props.$expand ? 'rotate(180deg)' : 'rotate(0deg)'};
`

const Content = styled.div`
    padding: 0 20rem 20rem;
    font-weight: 400;
    background: #fff;
    display: ${(props) => (props.$expand ? 'block' : 'none')};
    font-size: 14rem;
    border-radius: 0 0 8rem 8rem;
    @media ${device.tablet} {
        font-size: 14px;
        padding: 0 20px 20px;
        border-radius: 0 0 8px 8px;
    }
`

export default FaqCollapse
