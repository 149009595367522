import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { device } from 'style/devices'
import FaqCollapse from 'components/Explanation/FaqCollapse'
import { useTranslation } from 'react-i18next'
import FaqWhatPriority from 'components/FaqPage/FaqWhatPriority'
import bridge from 'utils/mobileJSBridge.js'
import { useNavigate, useLocation } from 'react-router-dom'

const Faq = () => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const { cuno } = useSelector((state) => state.urlParams)
    const { memberProfile } = useSelector((state) => state.profile)

    const toOptOut = () => {
        navigate(`/opt-out${location.search}`)
    }

    const toHkjc = (e) => {
        e.stopPropagation()
        bridge?.openExternalBrowser(t('faq.hkjcLink'))
    }
    const toCommunication = () => {
        navigate(`/communication-preference${location.search}`)
    }

    useEffect(() => {
        const internalAd = location.state?.internalAd
        window.adobeDataLayer?.push({
            event: 'pageview',
            page: {
                name: '',
                id: 'CWL-ClubWideLoyalty',
                language: i18n.language,
            },
            user: {
                cuno,
                segment_code: memberProfile?.segmentCode,
                pcard_tier: memberProfile?.ptierCode,
            },
            internal_ad: internalAd,
            report_suite: '3in1 App',
        })
        const u = navigator.userAgent
        console.log(
            navigator.userAgent,
            u.indexOf('iPhone'),
            'navigator.userAgent'
        )
    }, [])

    const toBottom = () => {
        document.body?.scrollTo(0, document.body.scrollHeight)
    }

    return (
        <Container>
            {/* <ExplainationTop title="FAQ" /> */}
            <ExplainationContent>
                <ExplainationWrap>
                    <FaqCollapseWrap>
                        <FaqCollapse index={0} title={t('faq.what')}>
                            <FaqWhatPriority></FaqWhatPriority>
                        </FaqCollapse>
                    </FaqCollapseWrap>
                    <FaqCollapseWrap>
                        <FaqCollapse index={1} title={t('faq.joinTitle')}>
                            <>
                                <Text>{t('faq.joinText1')}</Text>
                                <Text>{t('faq.joinText2')}</Text>
                            </>
                        </FaqCollapse>
                    </FaqCollapseWrap>
                    <FaqCollapseWrap>
                        <FaqCollapse index={2} title={t('faq.procedureTitle')}>
                            <Text>{t('faq.procedureText')}</Text>
                        </FaqCollapse>
                    </FaqCollapseWrap>
                    <FaqCollapseWrap>
                        <FaqCollapse index={3} title={t('faq.upgradedTitle')}>
                            <Text>{t('faq.upgradedText')}</Text>
                        </FaqCollapse>
                    </FaqCollapseWrap>
                    <FaqCollapseWrap>
                        <FaqCollapse index={4} title={t('faq.checkWinsTitle')}>
                            <Text>{t('faq.checkWinsText')}</Text>
                        </FaqCollapse>
                    </FaqCollapseWrap>
                    <FaqCollapseWrap>
                        <FaqCollapse index={5} title={t('faq.whatWinsTitle')}>
                            <Text>{t('faq.whatWinsText')}</Text>
                        </FaqCollapse>
                    </FaqCollapseWrap>
                    {/* <FaqCollapseWrap>
                        <FaqCollapse index={5} title={t('faq.joinRewardTitle')}>
                            <Text>{t('faq.joinRewardText')}</Text>
                        </FaqCollapse>
                    </FaqCollapseWrap> */}
                    <FaqCollapseWrap>
                        <FaqCollapse index={6} title={t('faq.earnWinsTitle')}>
                            <Text>{t('faq.earnWinsText')}</Text>
                            <TextListUl>
                                {new Array(5)
                                    .fill('faq.earnWins')
                                    .map((text, index) => (
                                        <Li key={`${text}${index + 1}`}>
                                            <Dot></Dot>
                                            <LiText>
                                                {t(`${text}${index + 1}`)}
                                            </LiText>
                                        </Li>
                                    ))}
                            </TextListUl>
                        </FaqCollapse>
                    </FaqCollapseWrap>
                    <FaqCollapseWrap>
                        <FaqCollapse index={7} title={t('faq.redeemTitle')}>
                            <Text>{t('faq.redeemText')}</Text>
                        </FaqCollapse>
                    </FaqCollapseWrap>
                    <FaqCollapseWrap>
                        <FaqCollapse index={8} title={t('faq.whatRedeemTitle')}>
                            <Text>{t('faq.whatRedeemText')}</Text>
                        </FaqCollapse>
                    </FaqCollapseWrap>

                    <FaqCollapseWrap>
                        <FaqCollapse
                            index={9}
                            title={t('faq.exchangedCashTitle')}
                        >
                            <Text>{t('faq.exchangedCashText')}</Text>
                        </FaqCollapse>
                    </FaqCollapseWrap>
                    <FaqCollapseWrap>
                        <FaqCollapse
                            index={10}
                            title={t('faq.rewardUpdatedTitle')}
                        >
                            <Text>{t('faq.rewardUpdatedText')}</Text>
                        </FaqCollapse>
                    </FaqCollapseWrap>
                    <FaqCollapseWrap>
                        <FaqCollapse
                            index={11}
                            title={t('faq.selectedRewardsTitle')}
                        >
                            <Text>{t('faq.selectedRewardsText')}</Text>
                            <Text>{t('faq.selectedRewardsTips')}</Text>
                            <TextListUl>
                                {new Array(4)
                                    .fill('faq.selectedRewardsStep')
                                    .map((text, index) => (
                                        <Li key={`${text}${index + 1}`}>
                                            <LiText>
                                                {t(`${text}${index + 1}`)}
                                            </LiText>
                                        </Li>
                                    ))}
                            </TextListUl>
                        </FaqCollapse>
                    </FaqCollapseWrap>
                    <FaqCollapseWrap>
                        <FaqCollapse
                            index={12}
                            title={t('faq.winsExpireTitle')}
                        >
                            <Text>{t('faq.winsExpireText')}</Text>
                        </FaqCollapse>
                    </FaqCollapseWrap>
                    <FaqCollapseWrap>
                        <FaqCollapse index={13} title={t('faq.winsRateTitle')}>
                            <Text>{t('faq.winsRateText')}</Text>
                        </FaqCollapse>
                    </FaqCollapseWrap>
                    <FaqCollapseWrap>
                        <FaqCollapse
                            index={14}
                            title={t('faq.usedCanceledTitle')}
                        >
                            <Text>{t('faq.usedCanceledText')}</Text>
                        </FaqCollapse>
                    </FaqCollapseWrap>
                    <FaqCollapseWrap>
                        <FaqCollapse
                            index={15}
                            title={t('faq.winsBalanceTitle')}
                        >
                            <Text>{t('faq.winsBalanceText')}</Text>
                        </FaqCollapse>
                    </FaqCollapseWrap>
                    <FaqCollapseWrap>
                        <FaqCollapse
                            index={16}
                            title={t('faq.howLongWinsTitle')}
                        >
                            <Text>{t('faq.howLongWinsText1')}</Text>
                            <Text>{t('faq.howLongWinsText2')}</Text>
                        </FaqCollapse>
                    </FaqCollapseWrap>
                    <FaqCollapseWrap>
                        <FaqCollapse index={17} title={t('faq.oneRecordTitle')}>
                            <Text>{t('faq.oneRecordText')}</Text>
                        </FaqCollapse>
                    </FaqCollapseWrap>
                    <FaqCollapseWrap>
                        <FaqCollapse
                            index={18}
                            title={t('faq.transferWinsTitle')}
                        >
                            <Text>{t('faq.transferWinsText')}</Text>
                        </FaqCollapse>
                    </FaqCollapseWrap>
                    <FaqCollapseWrap>
                        <FaqCollapse index={19} title={t('faq.seasonTitle')}>
                            <Text>{t('faq.seasonText')}</Text>
                        </FaqCollapse>
                    </FaqCollapseWrap>

                    <FaqCollapseWrap>
                        <FaqCollapse index={20} title={t('faq.questionTitle')}>
                            <Text>{t('faq.managerInfo')}</Text>
                            <Title>{t('faq.officeHoursTitle')}</Title>
                            <Text>{t('faq.dayTime')}</Text>
                            <Text>{t('faq.nightTime')}</Text>
                            <Text>{t('faq.sixTime')}</Text>
                        </FaqCollapse>
                    </FaqCollapseWrap>
                    <FaqCollapseWrap>
                        <FaqCollapse index={21} title={t('faq.bookTitle')}>
                            <Text>
                                {t('faq.hkjcLinkBefore')}
                                <A onClick={(e) => toHkjc(e)}>
                                    {t('faq.hkjcLink')}
                                </A>
                                {t('faq.hkjcLinkAfter')}
                            </Text>
                        </FaqCollapse>
                    </FaqCollapseWrap>
                    <FaqCollapseWrap>
                        <FaqCollapse
                            index={22}
                            title={t('faq.promotionsTitle')}
                        >
                            <Text>
                                {t('faq.promotionClickBefore')}
                                <A onClick={toCommunication}>
                                    {t('faq.promotionClick')}
                                </A>
                                {t('faq.promotionClickAfter')}
                            </Text>
                        </FaqCollapse>
                    </FaqCollapseWrap>
                    <FaqCollapseWrap>
                        <FaqCollapse
                            index={23}
                            toBottom={toBottom}
                            title={t('faq.quitTitle')}
                        >
                            <Text>
                                {t('faq.quitHereBefore')}
                                <A onClick={toOptOut}>{t('faq.quitHere')}</A>
                                {t('faq.quitHereAfter')}
                            </Text>
                        </FaqCollapse>
                    </FaqCollapseWrap>
                </ExplainationWrap>
            </ExplainationContent>
        </Container>
    )
}

const Container = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
`

const ExplainationContent = styled.div`
    width: 100%;
    background: #f0f0f0;
    padding: 20rem 20rem 20rem 30rem;
    flex: 1;
    @media ${device.tablet} {
        padding: 20px 20px 20px 30px;
    }
`

const ExplainationWrap = styled.div`
    z-index: 2;
    position: relative;
`

const FaqCollapseWrap = styled.div`
    margin-bottom: 6rem;
    @media ${device.tablet} {
        margin-bottom: 6px;
    }
`

const Text = styled.div`
    margin-bottom: 4px;
    font-size: 14rem;
    @media ${device.tablet} {
        font-size: 14px;
    }
`

const TextListUl = styled.div``

const Dot = styled.div`
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background: #000;
    flex-shrink: 0;
    margin-top: 8rem;
    margin-right: 6rem;
    @media ${device.tablet} {
        margin-top: 8px;
        margin-right: 6px;
        width: 4px;
        height: 4px;
    }
`

const Li = styled.div`
    font-size: 14rem;
    display: flex;
    margin-bottom: 4rem;
    padding-left: 4rem;
    @media ${device.tablet} {
        font-size: 14px;
    }
`

const LiText = styled.div`
    font-weight: 400;
`

const Title = styled.div`
    font-weight: 700;
    margin-bottom: 4rem;
    font-size: 14rem;
    @media ${device.tablet} {
        font-size: 16px;
    }
`

const A = styled.a`
    font-size: 14rem;
    font-weight: 700;
    text-decoration: underline;
    @media ${device.tablet} {
        font-size: 14px;
    }
`

export default Faq
