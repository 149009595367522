import { configureStore } from '@reduxjs/toolkit'
import profile from './modules/profile'
import urlParams from './modules/urlParams'
import appConfigs from './modules/appConfigs'

export const store = configureStore({
    reducer: {
        profile,
        urlParams,
        appConfigs,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }),
})
