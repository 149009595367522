import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import RegistrationFailLogo from 'assets/icons/registrationFail.svg'
import { device } from 'style/devices'
import { useTranslation } from 'react-i18next'
import PermissionShow from 'components/permission/show'
import bridge from 'utils/mobileJSBridge'

const PriorityRegistrationFailed = () => {
    const { t, i18n } = useTranslation()
    const { cuno } = useSelector((state) => state.urlParams)
    const { memberProfile } = useSelector((state) => state.profile)
    const urlParams = new URLSearchParams(window.location.search)
    const pCardMember = urlParams.get('isPCardMember') || 'false'

    useEffect(() => {
        window.adobeDataLayer?.push({
            event: 'pageview',
            page: {
                name:
                    pCardMember === 'false'
                        ? ''
                        : '/terms-and-conditions-failed',
                id: 'CWL-ClubWideLoyalty',
                language: i18n.language,
            },
            user: {
                cuno,
                segment_code: memberProfile?.segmentCode,
                pcard_tier: memberProfile?.ptierCode,
            },
            report_suite: '3in1 App',
        })
    })

    return (
        <StatusConetent>
            <FailedWrap>
                <Logo src={RegistrationFailLogo} />
                <Status>{t('PriorityRegistrationStatus.failed')}</Status>
                <Info>{t('PriorityRegistrationStatus.failedInfo')}</Info>
                <PermissionShow name="pcard-details">
                    <StatusButton onClick={() => bridge.closeWebView()}>
                        {t('PriorityRegistrationStatus.exit')}
                    </StatusButton>
                </PermissionShow>
            </FailedWrap>
        </StatusConetent>
    )
}

const StatusConetent = styled.div`
    height: 100vh;
    padding: 20rem 12rem 0;
    box-sizing: border-box;
    @media ${device.tablet} {
        padding: 20px 12px 0;
    }
`

const SuccessWrap = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 100rem;
    width: 100%;
    /* height: 360rem; */
    @media ${device.tablet} {
        margin-top: 100px;
        width: 100%;
    }
    /* background: ${(props) => `url(${props.$primary})`}; */
`

const FailedWrap = styled(SuccessWrap)`
    background: #fff;
`

const Status = styled.div`
    font-size: 18rem;
    font-weight: 700;
    line-height: 23rem;
    text-align: center;
    color: #000;
    margin-bottom: 12rem;
    padding: 0 28rem;
    @media ${device.tablet} {
        margin-bottom: 12px;
        line-height: 23px;
        font-size: 24px;
        padding: 0 28px;
    }
`

const StatusButton = styled.div`
    background: #003c84;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40rem;
    border-radius: 100rem;
    @media ${device.tablet} {
        height: 40px;
        border-radius: 100px;
        width: 70%;
    }
`

const Info = styled.div`
    font-size: 16rem;
    font-weight: 400;
    line-height: 21rem;
    text-align: center;
    color: #000;
    margin-bottom: 14rem;
    padding: 0 28rem;
    @media ${device.tablet} {
        font-size: 20px;
        line-height: 21px;
        margin-bottom: 24px;
        padding: 0 28px;
    }
`
const Logo = styled.img`
    width: 80rem;
    height: 80rem;
    @media ${device.tablet} {
        width: 160px;
        height: 160px;
    }
`

export default PriorityRegistrationFailed
