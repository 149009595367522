import React, { useState } from 'react'
import { LoadingContext } from 'components/Loading/context'
import Loading from 'components/Loading/index'

export const LoadingProvider = ({ children }) => {
    const [loading, setLoading] = useState(false)

    const showLoading = () =>
        setLoading(!(process.env.REACT_APP_PREVIEW_VERSION === 'true'))
    const hideLoading = () => setLoading(false)

    return (
        <LoadingContext.Provider value={{ loading, showLoading, hideLoading }}>
            {children}
            {loading ? <Loading /> : null}
        </LoadingContext.Provider>
    )
}
