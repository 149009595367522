import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { device } from 'style/devices'

const FaqWhatPriority = () => {
    const { t } = useTranslation()
    return (
        <Container>
            <Text>{t('faq.whatPriority')}</Text>
            <Title>{t('faq.newPriorityTitle')}</Title>
            <Text>{t('faq.newPriorityDesribe1')}</Text>
            <Text>{t('faq.newPriorityDesribe2')}</Text>
            <Title>{t('faq.tiersTitle')}</Title>
            <Text>{t('faq.colorCard')}</Text>
            <Text>{t('faq.cardTableInfo')}</Text>
            <Table>
                <Tbody>
                    <Tr>
                        <Th>{t('faq.points')}</Th>
                        <Th>{t('faq.tier')}</Th>
                    </Tr>
                    <Tr>
                        <CardTd>{t('priorityStatus.Yellow')}</CardTd>
                        <Td>0</Td>
                    </Tr>
                    <Tr>
                        <CardTd>{t('priorityStatus.Red')}</CardTd>
                        <Td>6000</Td>
                    </Tr>
                    <Tr>
                        <CardTd>{t('priorityStatus.Silver')}</CardTd>
                        <Td>10000</Td>
                    </Tr>
                    <Tr>
                        <CardTd>{t('priorityStatus.Gold')}</CardTd>
                        <Td>30000</Td>
                    </Tr>
                    <Tr>
                        <CardTd>{t('priorityStatus.Black')}</CardTd>
                        <Td>120000</Td>
                    </Tr>
                </Tbody>
            </Table>
            <Text>{t('faq.pointsInfo')}</Text>
            <TextListUl>
                {new Array(3).fill('faq.pointsStandard').map((text, index) => (
                    <Li key={`${text}${index + 1}`}>
                        <Dot></Dot>
                        <LiText>{t(`${text}${index + 1}`)}</LiText>
                    </Li>
                ))}
            </TextListUl>
        </Container>
    )
}

const Container = styled.div``

const Title = styled.div`
    font-weight: 700;
    margin-bottom: 4rem;
    font-size: 14rem;
    @media ${device.tablet} {
        font-size: 16px;
        margin-bottom: 4px;
    }
`

const Text = styled.div`
    margin-bottom: 4rem;
    font-size: 14rem;
    @media ${device.tablet} {
        font-size: 14px;
        margin-bottom: 4px;
    }
`
const TextListUl = styled.div``

const Dot = styled.div`
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background: #000;
    flex-shrink: 0;
    margin-top: 8rem;
    margin-right: 6rem;
    @media ${device.tablet} {
        margin-top: 8px;
        margin-right: 6px;
        width: 4px;
        height: 4px;
    }
`

const Li = styled.div`
    font-size: 14rem;
    display: flex;
    margin-bottom: 4rem;
    padding-left: 4rem;
    @media ${device.tablet} {
        font-size: 14px;
        margin-bottom: 4px;
        padding-left: 4px;
    }
`

const LiText = styled.div`
    font-weight: 400;
`

const Table = styled.table`
    border: 1px solid #222;
    width: 60%;
    border-collapse: collapse;
`
const Tbody = styled.tbody``

const Tr = styled.tr``

const Th = styled.th``

const Td = styled.td`
    text-align: center;
    padding: 2px;
`

const CardTd = styled.td`
    text-align: left;
    padding: 2px 2px 2px 10px;
    @media ${device.tablet} {
        padding: 2px 2px 2px 24%;
    }
`
export default FaqWhatPriority
