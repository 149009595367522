import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
export const isPermissionShow = ({ url, name }) => {
    const keys = new Set()
    url && keys.add(url)
    name && keys.add(name)
    const { configFunction } = useSelector((state) => state.appConfigs)
    if (configFunction[0] === '*') {
        return true
    }
    let count = 0
    return configFunction.some((i) => {
        if (keys.has(i)) {
            count++
        }
        return keys.size === count
    })
}

const permissionShow = ({ children, name, url }) => {
    const navigate = useNavigate()
    const isShow = isPermissionShow({ url, name })
    if (JSON.parse(process.env.REACT_APP_GET_APP_CONFIG)) {
        if (!isShow) {
            return
        }
        if (url) {
            return React.cloneElement(children, {
                onClick: () => {
                    navigate(`/${url}${location.search}`)
                },
            })
        }
        return children
    }
    return children
}

export default permissionShow
