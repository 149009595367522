import React from 'react'
import styled from 'styled-components'
import { device } from 'style/devices'
import ExplainTopBg from 'assets/banner/explainPage/explain-bg.png'

import PermissionShow from 'components/permission/show'
const ExplainationTop = (props) => {
    const { title } = props
    return (
        <Container $primary={ExplainTopBg}>
            <PermissionShow name="opt-out">
                <Title>{title}</Title>
            </PermissionShow>
        </Container>
    )
}

const Container = styled.div`
    /* height: 162rem; */
    width: 100%;
    background: #f0f0f0;
    position: relative;
    z-index: 1;
    padding: 20rem 0 30rem;
    flex-shrink: 0;
    @media ${device.tablet} {
        padding: 20px 0 34px;
        /* height: 162px; */
    }
`

const Title = styled.div`
    color: #222;
    font-size: 32rem;
    text-align: center;
    /* margin-top: 34rem; */
    @media ${device.tablet} {
        font-size: 32px;
        text-align: center;
        /* margin-top: 34px; */
    }
`

// const Line = styled.div`
//     background: #ffca3d;
//     width: 50rem;
//     height: 4rem;
//     margin-top: 10rem;
//     /* position: absolute; */
//     /* top: 100rem; */
//     @media ${device.tablet} {
//         width: 50px;
//         height: 4px;
//         margin-top: 10px;
//         /* top: 100px; */
//     }
// `

export default ExplainationTop
