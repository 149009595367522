import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getFontSize, device } from 'style/devices'
import { reqRedemptionList } from 'apis/lp/actions'
import { updateImagesUrlToPreviewPath } from 'utils/common'
import IconArrow from 'assets/icons/icon_arrow.png'

const PriorityRewardsWidget = ({ data }) => {
    const navigate = useNavigate()
    const { title, des } = data
    const { t } = useTranslation()
    const { models } = useSelector((state) => state.appConfigs)
    const { IconWatermark } = models?.['pcard-details']?.img
    const [gifts, setGifts] = useState(0)
    const urlParams = new URLSearchParams(location.search)

    useEffect(() => {
        const loadData = async () => {
            const p = {
                type: 'Active',
                sortName: '',
                limit: 10,
            }
            try {
                const result = await reqRedemptionList(p)
                const rewards = result?.data?.object?.total || 0
                console.log(rewards)
                setGifts(rewards)
            } catch (error) {
                console.log(error)
            }
        }
        loadData()
    }, [])

    return (
        <RewardsCardContainer
            onClick={() => {
                urlParams.delete('categoryCode')
                navigate(`/rewards?${urlParams}`, {
                    state: {
                        internalAd: 'SPLDSP_CWL/Home_Reward',
                        id: 'rewards',
                    },
                })
            }}
        >
            <TitleLabelMain>
                {t(title)}
                <InfoImgArray src={IconArrow} />
            </TitleLabelMain>
            {!(gifts === 0) && (
                <FlexBoxCol>
                    <Label>{t(des)}</Label>
                    <TitleLabel>{gifts}</TitleLabel>
                </FlexBoxCol>
            )}

            <BottomIcon src={updateImagesUrlToPreviewPath(IconWatermark)} />
        </RewardsCardContainer>
    )
}
const RewardsCardContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 120rem;
    width: 100%;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    @media ${device.tablet} {
        height: 130px;
    }
`
const Label = styled.div`
    z-index: 10;
    color: #000;
    font-size: ${getFontSize()?.mobileFontStyle.label2.fontSize}rem;
    font-style: normal;
    font-weight: 500;
    @media ${device.tablet} {
        font-size: ${getFontSize()?.tabletFontStyle.label2.fontSize}px;
    }
`
const TitleLabel = styled(Label)`
    font-size: ${getFontSize()?.mobileFontStyle.title1.fontSize}rem;
    @media ${device.tablet} {
        font-size: ${getFontSize()?.tabletFontStyle.title1.fontSize}px;
    }
`
const TitleLabelMain = styled.div`
    padding: 8rem 12rem;
    line-height: 120%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: ${getFontSize()?.mobileFontStyle.label1.fontSize}rem;
    background-image: linear-gradient(to right, #eeebe5, #ffffff);
    @media ${device.tablet} {
        padding: 8px 12px;
        font-size: ${getFontSize()?.tabletFontStyle.label1.fontSize}px;
    }
`
const FlexBoxCol = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    padding: 8px 12px;
`
const BottomIcon = styled.img`
    width: 72rem;
    height: 72rem;
    position: absolute;
    bottom: 0;
    right: 0;
    @media ${device.tablet} {
        width: 72px;
        height: 72px;
    }
`
const InfoImgArray = styled.img`
    width: 6rem;
    height: 10rem;
    @media ${device.tablet} {
        width: 6px;
        height: 10px;
    }
`
export default PriorityRewardsWidget
