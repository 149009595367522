import { useRef, useEffect } from 'react'

function useDidMount(callback) {
    const isMounted = useRef(false)

    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true
            return callback()
        }
    })
}

export default useDidMount
