import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import PrioritySpecialSection from './PrioritySpecialSection'
import PriorityExclusiveSection from './PriorityExclusiveSection'

const PriorityRewardView = () => {
    const { models } = useSelector((state) => state.appConfigs)
    const categoryParam = models?.['pcard-details']?.class
    const [giftCategories, setGiftCategories] = useState([])

    useEffect(() => {
        if (categoryParam) {
            setGiftCategories(categoryParam.split(';'))
        }
    }, [categoryParam])

    // const groupBy = (array) => {
    //     const groups = {}
    //     array.forEach((item) => {
    //         const giftCategoryName = item.giftCategoryName
    //         if (!groups[giftCategoryName]) {
    //             groups[giftCategoryName] = []
    //         }
    //         groups[giftCategoryName].push(item)
    //     })
    //     return Object.keys(groups).map((groupKey) => {
    //         return {
    //             title: groups[groupKey][0]
    //                 ? groups[groupKey][0].giftCategoryName
    //                 : '',
    //             children: groups[groupKey],
    //         }
    //     })
    // }

    return giftCategories.length > 0 ? (
        <PriorityRewardViewContainer>
            {giftCategories[0] && (
                <PrioritySpecialSection categoryCode={giftCategories[0]} />
            )}
            {giftCategories[1] && (
                <PriorityExclusiveSection categoryCode={giftCategories[1]} />
            )}
        </PriorityRewardViewContainer>
    ) : null
}

const PriorityRewardViewContainer = styled.div`
    /* margin-top: 24rem; */
`
export default PriorityRewardView
