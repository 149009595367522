import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
    name: 'profile',
    initialState: {
        memberProfile: {},
        memberProfileLoading: true,
    },
    reducers: {
        setUserProfile: (state, action) => {
            state.memberProfile = action.payload
        },
        setProfileLoaded: (state, action) => {
            state.memberProfileLoading = false
        },
        setProfileLoading: (state, action) => {
            state.memberProfileLoading = true
        },
    },
})

export const { setUserProfile, setProfileLoaded, setProfileLoading } =
    slice.actions
export default slice.reducer
