import axios from 'axios'
import configs from 'configs/configs'
import { v4 as uuidv4 } from 'uuid'
import { format } from 'date-fns'
const MAX_RETRIES = configs.API_MAX_RETRIES || 3
const axiosInstance = axios.create({
    baseURL: configs.MVP_API || 'https://crmint.corpdev.hkjc.com/crm/mvp/v1',
    headers: {
        'Content-Type': 'application/json',
        'X-Request-ID': uuidv4(),
        'X-Request-Datetime': format(new Date(), 'yyyy-mm-dd hh:mm:ss'),
    },
})

const refreshAndRetryQueue = []
let isRefreshing = false

const refreshAccessToken = () => {
    console.log('refreshed')
}

axiosInstance.interceptors.request.use(
    (config) => {
        const noBaseURLs = ['webViewConfigs']
        if (noBaseURLs.some((i) => config.url.includes(i))) {
            config.baseURL = ''
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

// Add global response interceptor
axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config
        originalRequest.__retryCount = originalRequest.__retryCount || 0

        if (originalRequest.__retryCount >= MAX_RETRIES)
            return Promise.reject(error)

        if (error.response && error.response.status === 401) {
            if (!isRefreshing) {
                isRefreshing = true
                try {
                    // Refresh the access token
                    const newAccessToken = await refreshAccessToken()

                    // Update the request headers with the new access token
                    error.config.headers.Authorization = `Bearer ${newAccessToken}`

                    // Retry all requests in the queue with the new token
                    refreshAndRetryQueue.forEach(
                        ({ config, resolve, reject }) => {
                            axiosInstance
                                .request(config)
                                .then((response) => resolve(response))
                                .catch((err) => reject(err))
                        }
                    )

                    // Clear the queue
                    refreshAndRetryQueue.length = 0
                    originalRequest.__retryCount += 1
                    // Retry the original request
                    return axiosInstance(originalRequest)
                } catch (refreshError) {
                    console.log(refreshError)
                    throw refreshError
                } finally {
                    isRefreshing = false
                }
            }

            // Add the original request to the queue
            return new Promise((resolve, reject) => {
                refreshAndRetryQueue.push({
                    config: originalRequest,
                    resolve,
                    reject,
                })
            })
        }

        // Return a Promise rejection if the status code is not 401
        return Promise.reject(error)
    }
)

export default axiosInstance
