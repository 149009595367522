if (!window.LoyaltyJSBridge) {
    console.log('No LoyaltyJSBridge object')
}
const mobileJSBridge = {
    retreiveJWTToken: async () => {
        window.LoyaltyJSBridge?.retreiveJWTToken()
    },
    // Setting up the app cache
    setLocalStorage: (key, value) => {
        console.log('setLocalStorage', key, value)
        window.LoyaltyJSBridge?.setLocalStorage({
            key,
            value,
        })
    },
    // Get app cache
    getLocalStorage: async (key) => {
        const result = await window.LoyaltyJSBridge?.getLocalStorage({
            key,
        })
        console.log(`localStorageValue[${key}]`, result)
        return result
    },
    openExternalBrowser: (url) => {
        window.LoyaltyJSBridge?.openExternalBrowser(url)
    },
    openEBQRCode: () => {
        window.LoyaltyJSBridge?.openEBQRCode()
    },
    cwlStatusChange: (status) => {
        window.LoyaltyJSBridge?.cwlStatusChange(status)
    },
    // app error
    exceptionHandleForCWL: (s) => {
        window.LoyaltyJSBridge?.exceptionHandleForCWL(s)
    },
    closeWebView: () => {
        window.LoyaltyJSBridge?.closeWebView()
    },
}

export default mobileJSBridge
