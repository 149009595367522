import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { device } from 'style/devices'
import styled from 'styled-components'
import RegistrationFailLogo from 'assets/icons/registrationFail.svg'
import OptOutContainer from 'components/Explanation/OptOutContainer'
import PermissionShow from 'components/permission/show'
import { useTranslation } from 'react-i18next'

const OptOutFailed = () => {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()
    const { memberProfile } = useSelector((state) => state.profile)
    const { cuno } = useSelector((state) => state.urlParams)

    useEffect(() => {
        window.adobeDataLayer?.push({
            event: 'pageview',
            page: {
                name: '',
                id: 'CWL-ClubWideLoyalty',
                language: i18n.language,
            },
            user: {
                cuno,
                segment_code: memberProfile?.segmentCode,
                pcard_tier: memberProfile?.ptierCode,
            },
            report_suite: '3in1 App',
        })
    }, [])

    return (
        <OptOutContainer>
            <Container>
                <InfoWrap>
                    <FailedWrap>
                        <Logo src={RegistrationFailLogo} />
                        <Info>{t('optout.failedInfo')}</Info>
                        <PermissionShow name="opt-out">
                            <StatusButton
                                onClick={() =>
                                    navigate(`/opt-out${location.search}`, {
                                        replace: true,
                                    })
                                }
                            >
                                {t('optout.failedBtn')}
                            </StatusButton>
                        </PermissionShow>
                    </FailedWrap>
                </InfoWrap>
            </Container>
        </OptOutContainer>
    )
}

const Container = styled.div`
    width: 100%;
    /* background: #1753a8; */
    padding: 10rem;
    display: flex;
    justify-content: center;
`

const InfoWrap = styled.div`
    width: 100%;
    border-radius: 10rem;
    padding: 20rem;
    background-color: #fff;
    height: 340rem;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media ${device.tablet} {
        width: 62%;
        border-radius: 0;
        padding: 30px;
        height: 376px;
    }
`

const SuccessWrap = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    @media ${device.tablet} {
        width: 100%;
    }
    /* background: ${(props) => `url(${props.$primary})`}; */
`

const FailedWrap = styled(SuccessWrap)`
    background: #fff;
`

const StatusButton = styled.div`
    background: #003c84;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40rem;
    border-radius: 100rem;
    @media ${device.tablet} {
        height: 40px;
        border-radius: 100px;
        width: 70%;
    }
`

const Info = styled.div`
    font-size: 16rem;
    font-weight: 400;
    line-height: 21rem;
    text-align: center;
    color: #000;
    margin: 20rem 0;
    @media ${device.tablet} {
        font-size: 20px;
        line-height: 21px;
        margin: 24px 0;
    }
`

const Logo = styled.img`
    width: 52rem;
    height: 52rem;
    @media ${device.tablet} {
        width: 104px;
        height: 104px;
    }
`

export default OptOutFailed
