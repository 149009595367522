import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLoading } from 'components/Loading/context'
import useDidMount from 'utils/useDidMount'
import PriorityCardHeadView from 'components/PCard/PriorityCardHeadView'
import PriorityCardBanner from 'components/PCard/PriorityCardBanner'
import PriorityCardWidgetView from 'components/PCard/PriorityCardWidgetView'
import PriorityRewardView from 'components/PCard/PriorityRewardView'
import IconInfo from 'assets/icons/icon_info.png'
import IconArrow from 'assets/icons/icon_arrow.png'
import IconCall from 'assets/icons/icon_call.png'
import PermissionShow from 'components/permission/show'
import { device, getFontSize } from 'style/devices'
import MobileBg from 'assets/banner/pcard-detail-bg.png'

const PriorityCardDetailsPage = () => {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()
    const { showLoading, hideLoading } = useLoading()
    const [reload, setReload] = useState(false)
    const { memberProfile, memberProfileLoading } = useSelector(
        (state) => state.profile
    )
    const { cuno } = useSelector((state) => state.urlParams)
    const navigateToAboutHKJCPriorityPage = () => {
        const internalAd = 'SPLDSP_CWL/Home_AboutProgramme'
        navigate(`/about-programme${location.search}`, {
            state: { internalAd },
        })
    }
    const toContactUs = () => {
        const internalAd = 'SPLDSP_CWL/Home_Help'
        navigate(`/contact-us${location.search}`, { state: { internalAd } })
    }

    useDidMount(() => {
        showLoading()
    })

    useEffect(() => {
        if (!memberProfileLoading) hideLoading()
    }, [memberProfileLoading])

    useEffect(() => {
        console.log('setReload', false)
        setReload(false)
        setTimeout(() => {
            console.log('setReload', true, 'ssssssssss')
            setReload(true)
            window.scroll(0, 1)
        }, 500)
    }, [])

    useEffect(() => {
        window.adobeDataLayer?.push({
            event: 'pageview',
            page: {
                name: '',
                id: 'CWL-ClubWideLoyalty',
                language: i18n.language,
            },
            user: {
                cuno,
                segment_code: memberProfile?.segmentCode,
                pcard_tier: memberProfile?.ptierCode,
            },
            report_suite: '3in1 App',
        })
    }, [])

    return (
        <PCardContainer>
            <PriorityCardHeadView />
            <StatusCardBox>
                {reload && <PriorityCardWidgetView />}
            </StatusCardBox>
            <PCardContainerBottom>
                <PermissionShow name="pcardDetails-banner">
                    <CardBannerBox>
                        {reload && <PriorityCardBanner />}
                    </CardBannerBox>
                </PermissionShow>
                <RewardsBannerBox>
                    <PriorityRewardView />
                </RewardsBannerBox>
                <ActionCellBox>
                    <PermissionShow name="about-programme">
                        <ActionCell onClick={navigateToAboutHKJCPriorityPage}>
                            <InfoImg src={IconInfo} />
                            <InfoLabel>{t('pcarddetail.aboutus')}</InfoLabel>
                            <InfoImgArray src={IconArrow} />
                        </ActionCell>
                    </PermissionShow>
                    <PermissionShow name="contact-us">
                        <ActionCell onClick={toContactUs}>
                            <InfoImg src={IconCall} />
                            <InfoLabel>{t('pcarddetail.contactus')}</InfoLabel>
                            <InfoImgArray src={IconArrow} />
                        </ActionCell>
                    </PermissionShow>
                </ActionCellBox>
            </PCardContainerBottom>
        </PCardContainer>
    )
}
const PCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    min-height: 100vh;
    background: url(${MobileBg});
    background-size: 100% 174rem;
    background-repeat: no-repeat;
    @media ${device.tablet} {
        background: url(${MobileBg});
        background-size: 100% auto;
    }
`

const PCardContainerBottom = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: #f0f0f0;
    padding-top: 50rem;
    @media ${device.tablet} {
        padding-top: 50px;
    }
`

const ActionCell = styled.div`
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 8rem;
    height: 40rem;
    margin: 8rem 12rem;
    padding-left: 12rem;
    padding-right: 10rem;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);

    @media ${device.tablet} {
        border-radius: 8px;
        height: 40px;
        margin: 8px 24px;
        padding-left: 12px;
        padding-right: 10px;
    }
`
const Label = styled.div`
    font-weight: 500;
    font-size: ${getFontSize()?.mobileFontStyle.h1.fontSize}rem;
`
const InfoLabel = styled(Label)`
    font-size: ${getFontSize()?.mobileFontStyle.label1.fontSize}rem;
    flex: 1;
    margin-left: 8rem;
    margin-right: 8rem;
    @media ${device.tablet} {
        font-size: ${getFontSize()?.tabletFontStyle.label1.fontSize}px;
        margin-left: 8px;
        margin-right: 8px;
    }
`
const InfoImgArray = styled.img`
    width: 6rem;
    height: 10rem;
    @media ${device.tablet} {
        width: 6px;
        height: 10px;
    }
`
const InfoImg = styled.img`
    width: 24rem;
    height: 24rem;
    @media ${device.tablet} {
        width: 24px;
        height: 24px;
    }
`
const CardBannerBox = styled.div``

const RewardsBannerBox = styled.div``

const ActionCellBox = styled.div`
    margin-top: 24rem;
    margin-bottom: 24rem;
    @media ${device.tablet} {
        margin-top: 24px;
        margin-bottom: 24px;
    }
`
const StatusCardBox = styled.div`
    margin-top: 16rem;
    margin-bottom: -54rem;
    z-index: 100;
    @media ${device.tablet} {
        margin-top: 24px;
        margin-bottom: -54px;
    }
`

export default PriorityCardDetailsPage
