import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { numberWithCommas, updateImagesUrlToPreviewPath } from 'utils/common'
import { device, getFontSize } from 'style/devices'
import bridge from 'utils/mobileJSBridge.js'
import IconQR from 'assets/icons/icon_qrcode.svg'
import IconEyeOpen from 'assets/icons/icon_eye_open.png'
import IconEyeClose from 'assets/icons/icon_eye_close.png'

const PriorityCardHeadView = () => {
    const { models } = useSelector((state) => state.appConfigs)
    const { t, i18n } = useTranslation()
    const { cuno } = useSelector((state) => state.urlParams)
    const { memberProfile } = useSelector((state) => state.profile)
    const IconTrophy = updateImagesUrlToPreviewPath(
        models?.common?.img?.IconTrophy
    )
    const IconDollar = updateImagesUrlToPreviewPath(
        models?.common?.img?.IconDollar
    )
    const [hideCwlPointBalance, setHideCwlPointBalance] = useState(false)
    const [point, setPoint] = useState(0)

    const isLessThanSixDigits = useMemo(() => {
        const p = memberProfile.pDollar ? Number(memberProfile.pDollar) : 0
        return p < 1000000
    }, [memberProfile])
    const mobileMatters = useMemo(() => {
        const p = memberProfile.pDollar ? Number(memberProfile.pDollar) : 0
        // WINS 8 digits->15px
        // WINS 7 digits -> 17px
        // WINS 6 digits->20px
        // WINS 5 digits->22px
        // Others 24px
        if (p < 10000) {
            return 24
        } else if (p < 100000) {
            return 22
        } else if (p < 1000000) {
            return 20
        } else if (p < 10000000) {
            return 17
        } else {
            return 15
        }
    }, [memberProfile])

    const [coin, setCoin] = useState(0)
    const { pcardBlack, pcardGold, pcardRed, pcardSilver, pcardYellow } =
        models.common.img

    const checkCardImage = (pTier) => {
        switch (pTier) {
            case 'Black':
                return updateImagesUrlToPreviewPath(pcardBlack)
            case 'Gold':
                return updateImagesUrlToPreviewPath(pcardGold)
            case 'Silver':
                return updateImagesUrlToPreviewPath(pcardSilver)
            case 'Red':
                return updateImagesUrlToPreviewPath(pcardRed)
            case 'Yellow':
                return updateImagesUrlToPreviewPath(pcardYellow)
            default:
                return null
        }
    }

    const showQR = () => {
        bridge.openEBQRCode()
        window.adobeDataLayer?.push({
            event: 'interaction',
            interaction: {
                eventID: 'CWL_Click_Home-Qrcode',
            },
            page: {
                name: '',
                id: 'CWL-ClubWideLoyalty',
                language: i18n.language,
            },
            user: {
                cuno,
                segment_code: memberProfile?.segmentCode,
                pcard_tier: memberProfile?.ptierCode,
            },
            report_suite: '3in1 App',
        })
    }

    const eyeClick = () => {
        bridge.setLocalStorage('cwlPointBalanceMask', !hideCwlPointBalance + '')
        setHideCwlPointBalance(!hideCwlPointBalance)
        window.adobeDataLayer?.push({
            event: 'interaction',
            interaction: {
                eventID: !hideCwlPointBalance
                    ? 'CWL_Click_Home-Mask'
                    : 'CWL_Click_Home-Unmask',
            },
            page: {
                name: '',
                id: 'CWL-ClubWideLoyalty',
                language: i18n.language,
            },
            user: {
                cuno,
                segment_code: memberProfile?.segmentCode,
                pcard_tier: memberProfile?.ptierCode,
            },
            report_suite: '3in1 App',
        })
    }

    useEffect(() => {
        setPoint(numberWithCommas(memberProfile.pDollar || null))
        setCoin(
            numberWithCommas(Math.floor((memberProfile.pDollar || null) / 50))
        )
    }, [memberProfile])

    useEffect(() => {
        const getCwlPointBalanceMask = async () => {
            const hidePointBalance = await bridge.getLocalStorage(
                'cwlPointBalanceMask'
            )
            console.log('cwlPointBalanceMask', hidePointBalance)
            setHideCwlPointBalance(hidePointBalance === 'true')
        }
        getCwlPointBalanceMask()
    }, [])

    return (
        <PriorityCardHeadContainer>
            <PriorityCardHeadPointLeft>
                <PointBarLabel>
                    <TitleLabel> {t('pcard-details.prioritypoint')}</TitleLabel>
                    <EyeIcon
                        onClick={eyeClick}
                        src={hideCwlPointBalance ? IconEyeOpen : IconEyeClose}
                    />
                </PointBarLabel>
                <PointDown>
                    {hideCwlPointBalance ? (
                        <React.Fragment>
                            <RewardIcon src={IconTrophy} />
                            <Label
                                $params={{
                                    mobileMatters: mobileMatters ?? 15,
                                    isLessThanSixDigits,
                                }}
                            >
                                {point || '0'}
                            </Label>
                            <EqualLabel
                                $params={{
                                    mobileMatters: mobileMatters ?? 15,
                                    isLessThanSixDigits,
                                }}
                            >
                                =
                            </EqualLabel>
                            <DollarIcon src={IconDollar} />
                            <Label
                                $params={{
                                    mobileMatters: mobileMatters ?? 15,
                                    isLessThanSixDigits,
                                }}
                            >
                                {coin || '0'}
                            </Label>
                        </React.Fragment>
                    ) : (
                        <Label
                            $params={{
                                mobileMatters: mobileMatters ?? 15,
                                isLessThanSixDigits,
                            }}
                        >
                            *****
                        </Label>
                    )}
                </PointDown>
            </PriorityCardHeadPointLeft>
            <PriorityCardHeadPointRight onClick={showQR}>
                <CardImgBox>
                    <CardImg src={checkCardImage(memberProfile.pTier)} />
                </CardImgBox>

                <QRView>
                    <ImgQRCode src={IconQR} />
                </QRView>
            </PriorityCardHeadPointRight>
        </PriorityCardHeadContainer>
    )
}

const PriorityCardHeadContainer = styled.div`
    color: white;
    padding: 16rem 12rem;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    position: relative;
    @media ${device.tablet} {
        padding: 14px 24px;
    }
`
const PriorityCardHeadPointLeft = styled.div``
const PointDown = styled.div`
    display: flex;
    margin-top: 4rem;
    align-items: center;
    height: 30rem;
    @media ${device.tablet} {
        margin-top: 8px;
        height: 30px;
    }
`
// line-height: ${(props) =>
//     `${getFontSize()?.mobileFontStyle[props?.$mobileMatters].lineHeight}rem`};
const Label = styled.div`
    font-weight: 500;
    line-height: ${(props) => {
        return `${props?.$params?.mobileMatters}rem`
    }};
    font-size: ${(props) => `${props?.$params?.mobileMatters}rem`};
    @media ${device.tablet} {
        line-height: ${(props) => {
            return props?.$params?.isLessThanSixDigits
                ? `${getFontSize()?.tabletFontStyle.h1.lineHeight}px`
                : `${getFontSize()?.tabletFontStyle.title1.lineHeight}px`
        }};
        font-size: ${(props) =>
            props?.$params?.isLessThanSixDigits
                ? `${getFontSize()?.tabletFontStyle.h1.fontSize}px`
                : `${getFontSize()?.tabletFontStyle.title1.fontSize}px`};
    }
`
const EqualLabel = styled(Label)`
    margin-left: 4rem;
    margin-bottom: 2rem;
    line-height: ${(props) => `${props?.$params?.mobileMatters}rem`};
    font-size: ${(props) => `${props?.$params?.mobileMatters}rem`};
    @media ${device.tablet} {
        margin-left: 4px;
        margin-bottom: 2px;
        line-height: ${(props) =>
            props?.$params?.isLessThanSixDigits
                ? `${getFontSize()?.tabletFontStyle.h1.lineHeight}px`
                : `${getFontSize()?.tabletFontStyle.title1.lineHeight}px`};
        font-size: ${(props) =>
            props?.$params?.isLessThanSixDigits
                ? `${getFontSize()?.tabletFontStyle.h1.fontSize}px`
                : `${getFontSize()?.tabletFontStyle.title1.fontSize}px`};
    }
`
const TitleLabel = styled.span`
    color: #fff;
    font-size: ${getFontSize()?.mobileFontStyle.title3.fontSize}rem;
    font-weight: 500;
    line-height: ${getFontSize()?.mobileFontStyle.title1.fontSize}rem;
    @media ${device.tablet} {
        font-size: ${getFontSize()?.tabletFontStyle.title3.fontSize}px;
        line-height: ${getFontSize()?.tabletFontStyle.title3.lineHeight}px;
    }
`
const Icon = styled.img`
    width: 24rem;
    height: 24rem;
    @media ${device.tablet} {
        width: 24px;
        height: 24px;
    }
`
const RewardIcon = styled(Icon)`
    margin-right: 4rem;
    @media ${device.tablet} {
        margin-right: 4px;
    }
`
const DollarIcon = styled(Icon)`
    margin-left: 4rem;
    margin-right: 4rem;
    @media ${device.tablet} {
        margin-right: 4px;
        margin-left: 4px;
    }
`

const PriorityCardHeadPointRight = styled.div`
    position: relative;
`
const PointBarLabel = styled.div`
    display: flex;
    align-items: center;
`
const CardImgBox = styled.div`
    width: 80rem;
    height: 51rem;
    margin-top: 4rem;
    margin-right: 16rem;
    border-radius: 5rem;
    border-width: 3rem 3rem 3rem 3rem;
    border-style: solid;
    border-color: rgba(47, 102, 131, 0.6);
    overflow: hidden;
    @media ${device.tablet} {
        width: 80px;
        height: 51px;
        margin-top: 4px;
        margin-right: 16px;
        border-radius: 5px;
        border-width: 3px;
    }
`
const CardImg = styled.img`
    width: 80rem;
    height: 51rem;
    position: relative;
    left: -1px;
    top: -1px;
    @media ${device.tablet} {
        width: 80px;
        height: 51px;
    }
`
const QRView = styled.div`
    box-sizing: border-box;
    width: 36rem;
    height: 36rem;
    position: absolute;
    right: 0;
    bottom: -17rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @media ${device.tablet} {
        width: 36px;
        height: 36px;
        right: 0;
        bottom: -9px;
    }
`
const ImgQRCode = styled.img`
    width: 36rem;
    height: 36rem;
    @media ${device.tablet} {
        width: 36px;
        height: 36px;
    }
`
const EyeIcon = styled.img`
    width: 24rem;
    height: 24rem;
    @media ${device.tablet} {
        width: 24px;
        height: 24px;
    }
`

export default PriorityCardHeadView
