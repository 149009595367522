export const numberWithCommas = (x) => {
    if (!x) return x
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const roundDownNumber = (x) => {
    if (!x) return x
    return Math.floor(x)
}

export const updateImagesUrlToPreviewPath = (x) => {
    if (!x) return x
    if (process.env.REACT_APP_PREVIEW_VERSION === 'true') {
        return x.replace(
            process.env.REACT_APP_CDN_PATH,
            process.env.REACT_APP_CDN_PREVIEW_PATH
        )
    } else {
        return x
    }
}

export const convertMemberProfileResults = (wcrmMemberProfile) => {
    const memberProfile = {
        customerId: wcrmMemberProfile?.custId,
        loyaltyId: wcrmMemberProfile?.loyaltyId,
        betAccountNo: wcrmMemberProfile?.betAcNo,
        pcardNo: wcrmMemberProfile?.pcardNo,
        membershipNo: wcrmMemberProfile?.memNo,
        accountType: wcrmMemberProfile?.acctType,
        pDollar: wcrmMemberProfile?.pDollar,
        pDollarDetails: wcrmMemberProfile?.pDollarDetails,
        pTier: wcrmMemberProfile?.ptier,
        ptierCode: wcrmMemberProfile?.cardTierCode,
        salulation: wcrmMemberProfile?.salulation,
        lastName: wcrmMemberProfile?.lastname,
        firstName: wcrmMemberProfile?.firstname,
        validTill: wcrmMemberProfile?.validTill,
        coinBal: wcrmMemberProfile?.coinBal,
        memNo: wcrmMemberProfile?.memNo,
        segmentCode: wcrmMemberProfile?.segmentCode,
        spendableVal: wcrmMemberProfile?.spendableVal,
        isDirectMkt: wcrmMemberProfile?.isDirectMkt,
    }
    return memberProfile
}
