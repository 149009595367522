import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { keyframes } from 'styled-components'
import loadingIcon from 'assets/icons/loading.png'

import { device } from 'style/devices'
const urlParams = new URLSearchParams(window.location.search)
const lang = urlParams.get('lang')

const Loading = ({ resources }) => {
    const { t } = useTranslation()
    return (
        <React.Fragment>
            <Loadingbg></Loadingbg>
            <LoadingWapper>
                <LoadingIcon src={loadingIcon}></LoadingIcon>
                <LoadingText>
                    {resources?.[lang]?.common?.loading || t('common.loading')}
                </LoadingText>
            </LoadingWapper>
        </React.Fragment>
    )
}

const Loadingbg = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #000;
    opacity: 20%;
    z-index: 9999;
`

const LoadingWapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    border-radius: 24px;
    transform: translate(-145rem, -60rem);
    width: 290rem;
    height: 120rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    z-index: 9999;
    @media ${device.tablet} {
        width: 290px;
        height: 120px;
        transform: translate(-145px, -60px);
    }
`
const loadingIconAnimate = keyframes`
   from{
       transform:rotate(0deg)
   }
   to{
       transform:rotate(360deg)
   }
`
const LoadingIcon = styled.img`
    height: 40rem;
    width: 40rem;
    animation: ${loadingIconAnimate} 2s infinite linear;
    @media ${device.tablet} {
        height: 40px;
        width: 40px;
    }
`

const LoadingText = styled.div`
    font-size: 16rem;
    color: #333333;
    @media ${device.tablet} {
        font-size: 16px;
    }
`

export default Loading
