import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import Button from 'components/Button'
import FailIcon from 'assets/icons/icon_fail.png'
import { device } from 'style/devices'
import PermissionShow from 'components/permission/show'

const RewardsFailed = () => {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()
    const { memberProfile } = useSelector((state) => state.profile)
    const { cuno } = useSelector((state) => state.urlParams)
    const location = useLocation()
    const message = location?.state?.message || ''

    useEffect(() => {
        window.adobeDataLayer?.push({
            event: 'pageview',
            page: {
                name: '',
                id: 'CWL-ClubWideLoyalty',
                language: i18n.language,
            },
            user: {
                cuno,
                segment_code: memberProfile?.segmentCode,
                pcard_tier: memberProfile?.ptierCode,
            },
            report_suite: '3in1 App',
        })
        history.pushState({}, null, `/rewards${location.search}`)
        console.log('==================')
        history.pushState({}, null, `/rewards-failed${location.search}`)
    }, [])

    return (
        <RewardsSuccessWapper>
            <RewardsSuccessImg src={FailIcon}></RewardsSuccessImg>
            <RewardsSuccessTitle>{t('reward.faile')}</RewardsSuccessTitle>
            <RewardsSuccessDescriptive>
                {message || t('reward.redemptionErrorMsg.error')}
            </RewardsSuccessDescriptive>
            <PermissionShow name="pcard-details">
                <Button
                    onClick={() => {
                        navigate(`/rewards${location.search}`, {
                            state: {
                                id: 'rewards',
                                internalAd:
                                    'SPLDSP_CWL/RewardS4-moneyFail_BackRewardList',
                            },
                        })
                    }}
                >
                    {t('reward.BackRewardList')}
                </Button>
            </PermissionShow>
        </RewardsSuccessWapper>
    )
}

const RewardsSuccessWapper = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 60rem;
    @media ${device.tablet} {
        padding: 0 60px;
    }
`
const RewardsSuccessImg = styled.img`
    width: 52rem;
    height: 52rem;
    margin: 0 auto 16rem;
    @media ${device.tablet} {
        width: 52px;
        height: 52px;
        margin: 0 auto 16px;
    }
`
const RewardsSuccessTitle = styled.div`
    font-size: 18rem;
    font-weight: 700;
    line-height: 23rem;
    margin: 0;
    @media ${device.tablet} {
        font-size: 20px;
        line-height: 23px;
    }
`
const RewardsSuccessDescriptive = styled.div`
    margin: 0;
    font-weight: 400;
    line-height: 21rem;
    display: flex;
    align-items: center;
    justify-content: center;
    @media ${device.tablet} {
        font-size: 18px;
        line-height: 23px;
        margin-top: 5px;
    }
`

export default RewardsFailed
