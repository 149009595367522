import React, { useEffect, useState } from 'react'
import { jwtDecode } from 'jwt-decode'
import useDidMount from 'utils/useDidMount'
import { device } from 'style/devices'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useLoading } from 'components/Loading/context'
import bridge from 'utils/mobileJSBridge.js'
import Radio from 'components/Radio/index'
import { getMemberProfile, setDirectMarketingFlap } from 'apis/mvp/actions'
import { setUserProfile, setProfileLoaded } from 'store/modules/profile'
import { convertMemberProfileResults } from 'utils/common'

const CommunicationPreference = () => {
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    const { showLoading, hideLoading } = useLoading()
    const { cuno } = useSelector((state) => state.urlParams)
    const { memberProfile } = useSelector((state) => state.profile)
    const initialValue = memberProfile?.isDirectMkt || false
    const [isReceiveMarketing, setIsReceiveMarketing] = useState(initialValue)
    const currentLanguage = i18n.language
    const openBroswer = (url) => {
        bridge.openExternalBrowser(url)
    }

    useDidMount(() => showLoading())

    const updateDirectMarketingFlap = () => {
        setIsReceiveMarketing(!isReceiveMarketing)
        const token = localStorage.getItem('api-token')
        const decoded = jwtDecode(token)
        const headers = {}
        const path = `${decoded.sub}/update`
        const isDirectMarketing = !memberProfile?.isDirectMkt
        const body = {
            accType: decoded?.type,
            isDirectMarketing,
        }
        setDirectMarketingFlap(body, headers, path)
            .then((response) => {
                console.log('Set DirectMarketing Flap', response)
                hideLoading()
            })
            .catch((error) => {
                console.log(error)
                hideLoading()
            })
    }

    const getMemberProfileInformation = () => {
        const body = {
            lang: i18n.language?.toLocaleLowerCase() || 'en-us',
        }
        const headers = {
            'X-Request-JWT': localStorage.getItem('api-token'),
            'X-Request-AccEntryCode': 'Jwt',
        }
        getMemberProfile(body, headers)
            .then((response) => {
                const wcrmMemberProfile = response.data.data
                console.log('Member Profile responses', response)
                const memberProfile =
                    convertMemberProfileResults(wcrmMemberProfile)
                dispatch(setUserProfile(memberProfile))
                dispatch(setProfileLoaded())
                hideLoading()
            })
            .catch((error) => {
                console.log(error, 'error')
                hideLoading()
            })
    }

    const setDirectMarketingClicked = () => {
        showLoading()
        updateDirectMarketingFlap()
        window.adobeDataLayer?.push({
            event: 'interaction',
            interaction: {
                eventID: 'CWL_Click_MarketingPref-Change',
            },
            page: {
                name: '',
                id: 'CWL-ClubWideLoyalty',
                language: i18n.language,
            },
            user: {
                cuno,
                segment_code: memberProfile?.segmentCode,
                pcard_tier: memberProfile?.ptierCode,
            },
            report_suite: '3in1 App',
        })
    }

    useEffect(() => {
        window.adobeDataLayer?.push({
            event: 'pageview',
            page: {
                name: '',
                id: 'CWL-ClubWideLoyalty',
                language: i18n.language,
            },
            user: {
                cuno,
                segment_code: memberProfile?.segmentCode,
                pcard_tier: memberProfile?.ptierCode,
            },
            report_suite: '3in1 App',
        })
        getMemberProfileInformation()
    }, [])

    useEffect(() => {
        setIsReceiveMarketing(initialValue)
    }, [initialValue])

    return (
        <Container>
            <Info>
                {t('communication.terms')}{' '}
                <A
                    onClick={() =>
                        openBroswer(
                            currentLanguage === 'en-US'
                                ? 'https://www.hkjc.com/home/english/corporate/corp_privacy.aspx'
                                : 'https://www.hkjc.com/home/chinese/corporate/corp_privacy.aspx'
                        )
                    }
                >
                    {t('communication.openUrl')}
                </A>
                {currentLanguage === 'en-US' ? '.' : '。'}
            </Info>
            <RadioBox>
                <RadioCard>
                    <Receive>{t('communication.receive')}</Receive>
                    <Radio
                        checked={isReceiveMarketing}
                        text={
                            isReceiveMarketing
                                ? t('communication.on')
                                : t('communication.off')
                        }
                        setChecked={() => setDirectMarketingClicked()}
                    ></Radio>
                </RadioCard>
                <Tips>{t('communication.tips')}</Tips>
            </RadioBox>
        </Container>
    )
}

const Container = styled.div`
    padding: 12rem;
    height: 100vh;
    @media ${device.tablet} {
        padding: 12px;
    }
`

const Info = styled.div`
    font-size: 16rem;
    font-weight: 400;
    margin-bottom: 16rem;
    line-height: 21rem;
    @media ${device.tablet} {
        padding: 16px;
        margin-bottom: 16px;
        font-size: 16px;
        line-height: 21px;
    }
`

const A = styled.a`
    font-weight: 400;
    font-size: 16rem;
    text-decoration: underline;
    @media ${device.tablet} {
        margin-bottom: 12px;
        font-size: 16px;
    }
`

const RadioCard = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
`

const RadioBox = styled.div`
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: white;
    padding: 10rem 12rem;
    box-shadow: 0px 2px 12px 0px #617ca833;
    @media ${device.tablet} {
        padding: 10px 12px;
    }
`

const Tips = styled.div`
    font-size: 13rem;
    color: rgba(85, 85, 85, 1);
    margin-top: 8rem;
    @media ${device.tablet} {
        font-size: 13px;
        margin-top: 8px;
    }
`

const Receive = styled.div`
    font-size: 16rem;
    font-weight: 500;
    @media ${device.tablet} {
        font-size: 16px;
    }
`

export default CommunicationPreference
