import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import IconArrow from 'assets/icons/icon_arrow.png'
import { getFontSize, device } from 'style/devices'

const PriorityStatusWidget = ({ data }) => {
    const navigate = useNavigate()
    const { title, des } = data
    const { t } = useTranslation()
    const { memberProfile } = useSelector((state) => state.profile)
    const progress = [
        {
            maintain: 0,
            end: 6000,
            type: 'yellowCard',
            index: 0,
            name: 'Yellow',
            color: '#f7d259',
        },
        {
            maintain: 6000,
            end: 10000,
            type: 'redCard',
            index: 1,
            name: 'Red',
            color: '#a53d51',
        },

        {
            maintain: 10000,
            end: 30000,
            type: 'silverCard',
            index: 2,
            name: 'Silver',
            color: '#a0a4a8',
        },
        {
            maintain: 30000,
            end: 120000,
            type: 'goldCard',
            index: 3,
            name: 'Gold',
            color: '#bb9859',
        },
        {
            maintain: 120000,
            end: 120000,
            type: 'blackCard',
            index: 4,
            name: 'Black',
            color: '#1c1c1c',
        },
    ]

    const getTheEndValue = () => {
        const len = progress.length
        for (let i = 0; i < len; i++) {
            if (memberProfile?.coinBal < progress[i].end) {
                console.log(progress[i].end)
                return progress[i].end
            }
        }
        return progress[len - 1].end
    }

    const getTheProgressValue = () => {
        const value = getTheEndValue()
        const result = memberProfile?.coinBal / value
        console.log(result, 're')
        return result || 0
    }

    const currentColor = useMemo(() => {
        const i = progress?.findIndex((d) => {
            return d.name === memberProfile?.pTier
        })
        return i ? progress?.[i]?.color : progress?.[0]?.color
    }, [memberProfile])

    return (
        <PriorityCardContainer
            onClick={() =>
                navigate('/priority-status' + location.search, {
                    state: {
                        internalAd: 'SPLDSP_CWL/Home_Tier',
                    },
                })
            }
        >
            <PriorityTitleLabel>
                {t(title)}
                <InfoImgArray src={IconArrow} />
            </PriorityTitleLabel>
            <FlexBottomBox>
                <PriorityLabel>{t(des)}</PriorityLabel>
                <PriorityNumLabel>{memberProfile.coinBal}</PriorityNumLabel>
                <ProgressBox>
                    <PointProgress>
                        <PointColorBar
                            value={getTheProgressValue()}
                            currentColor={currentColor}
                        />
                    </PointProgress>
                    <PointIconBox
                        currentColor={currentColor}
                        $full={
                            currentColor ===
                            progress[progress.length - 1]?.color
                                ? getTheProgressValue() >= 1
                                : getTheProgressValue() === 1
                        }
                    ></PointIconBox>
                </ProgressBox>
            </FlexBottomBox>
        </PriorityCardContainer>
    )
}

const PriorityCardContainer = styled.div`
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 120rem;
    width: 100%;
    background-color: #fff;

    @media ${device.tablet} {
        height: 130px;
    }
`
const PriorityLabel = styled.div`
    color: #000;
    font-weight: 500;
    line-height: 120%;
    font-size: ${getFontSize()?.mobileFontStyle.label2.fontSize}rem;
    @media ${device.tablet} {
        font-size: ${getFontSize()?.tabletFontStyle.label2.fontSize}px;
    }
`
const PriorityTitleLabel = styled(PriorityLabel)`
    padding: 8rem 12rem;
    background-image: linear-gradient(to right, #eeebe5, #ffffff);
    box-sizing: border-box;
    font-size: ${getFontSize()?.mobileFontStyle.label1.fontSize}rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media ${device.tablet} {
        padding: 8px 12px;
        font-size: ${getFontSize()?.tabletFontStyle.label1.fontSize}px;
    }
`
const PriorityNumLabel = styled(PriorityLabel)`
    font-size: ${getFontSize()?.mobileFontStyle.title1.fontSize}rem;
    line-height: ${getFontSize()?.mobileFontStyle.title1.lineHeight}rem;
    @media ${device.tablet} {
        font-size: ${getFontSize()?.tabletFontStyle.title1.fontSize}px;
        line-height: ${getFontSize()?.tabletFontStyle.title1.lineHeight}px;
    }
`

const ProgressBox = styled.div`
    display: flex;
    align-items: center;
`
const PointProgress = styled.div`
    display: flex;
    flex: 1;
    height: 4rem;
    background-color: #cbd9ed;
    border-radius: 100rem;
    @media ${device.tablet} {
        height: 4px;
        border-radius: 100px;
    }
`
const PointColorBar = styled.div`
    height: 4rem;
    width: ${(props) => props.value * 100 + '%'};
    background-color: ${(props) => props.currentColor};
    border-radius: 100rem;
    @media ${device.tablet} {
        height: 4px;
        border-radius: 100px;
    }
`
const PointIconBox = styled.div`
    position: relative;
    right: 2px;
    box-sizing: border-box;
    background-color: #fff;
    border: 4rem solid;
    border-color: ${(props) => (props.$full ? props.currentColor : '#cbd9ed')};
    width: 14rem;
    height: 14rem;
    border-radius: 14rem;
    @media ${device.tablet} {
        border: 5px solid;
        width: 16px;
        height: 16px;
        border-radius: 16px;
        border-color: ${(props) =>
            props.$full ? props.currentColor : '#cbd9ed'};
    }
`
const FlexBottomBox = styled.div`
    padding: 8rem 12rem;
    @media ${device.tablet} {
        padding: 14px 12px 8px;
    }
`
const InfoImgArray = styled.img`
    width: 6rem;
    height: 10rem;
    @media ${device.tablet} {
        width: 6px;
        height: 10px;
    }
`

export default PriorityStatusWidget
