import lpServer from './index'

export function optIn(source, headers) {
    return lpServer.post(
        process.env.REACT_APP_OPT_IN,
        {
            optInChannel: source,
            operationType: 'Opt-in',
        },
        {
            headers,
        }
    )
}

export function optOut(source, headers) {
    return lpServer.post(
        process.env.REACT_APP_OPT_IN,
        {
            optInChannel: source,
            operationType: 'Opt-out',
        },
        {
            headers,
        }
    )
}

// get redemption list
export function reqRedemptionList(data) {
    return lpServer({
        url: process.env.REACT_APP_REDEMPTION_LIST,
        method: 'post',
        data,
    })
}
// get redemption detail by id
export function reqRedemptionDetail(id) {
    return lpServer.get(
        `GET?routePath=api/reward.app/Get?routePath=gift/getRedemptionDetail?memberGiftId=${id}`
    )
}
// get gift list
export function reqGiftList(data) {
    return lpServer({
        url: process.env.REACT_APP_GIFT_LIST,
        method: 'post',
        data,
    })
}
// get gift list
export function reqGiftDetailById(id) {
    const data = {}
    return lpServer({
        url: `${process.env.REACT_APP_GIFT_DETAIL}${id}`,
        method: 'post',
        data,
    })
}

// redeem gift
export function reqRedeemGift(data) {
    return lpServer({
        url: process.env.REACT_APP_REDEEM_GIFT,
        method: 'post',
        data,
    })
}
// redemption callback
export function reqRedemptionCallback(data) {
    return lpServer.post(process.env.REACT_APP_REDEMPTION_CALLBACK, data)
}
