import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { device } from 'style/devices'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import './reset-swiper.css'
import { Autoplay, Pagination, Navigation } from 'swiper/modules'
import { useQuery, gql } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import mobileJSBridge from 'utils/mobileJSBridge'
import useMedia from 'hooks/useMedia'
import { useNavigate } from 'react-router-dom'

const PriorityCardBanner = () => {
    const { i18n } = useTranslation()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const currentLanguage = i18n.language

    const [banners, setBanners] = useState([])

    const itemClick = (item) => {
        console.log('banner item click', JSON.stringify(item))
        const { bannerDest, bannerDestURL } = item
        if (bannerDest?.targetItem?.name === 'In app browser') {
            const bannerDestUrl = bannerDestURL?.value
            if (bannerDestUrl) {
                const url = bannerDestUrl.split('?')?.[0] || ''
                const isQueryParameter = bannerDestUrl.split('?')?.[1] || ''
                if (isQueryParameter) {
                    const testurlParams = new URLSearchParams(isQueryParameter)
                    const rewardId = testurlParams.get('rewardId')
                    const urlParams = new URLSearchParams(location.search)
                    urlParams.set('rewardId', rewardId)
                    navigate(`${url}?${urlParams.toString()}`)
                } else {
                    navigate(`${bannerDestUrl}${location.search}`)
                }
            }
        } else if (bannerDest?.targetItem?.name === 'External browser') {
            if (bannerDestURL?.value)
                mobileJSBridge.openExternalBrowser(bannerDestURL.value)
        }
    }
    const minW = useMedia(device.tablet)

    const GET_DATA = gql`
        fragment FullWidthBanner on FullWidthBanner {
            id
            name
            bannerDestURL: field(name: "bannerDestURL") {
                value
            }
            bannerImage: field(name: "bannerImage") {
                ... on ImageField {
                    src
                }
            }
            bannerDest: field(name:"bannerDest") {
            	... on LookupField {
              		value,
              		targetItem {
                		name
              		}
            	}
          	}	
        }
        fragment FullWidthBannerList on FullWidthBannerList {
            BannerOrdering: field(name: "bannerOrder") {
                ... on MultilistField {
                    value
                    targetItems {
                        ...FullWidthBanner
                    }
                }
            }
        }

        query ($lang: String = "${currentLanguage}") {
            matchpreview: item(
                path: "/sitecore/content/Sites/LoyaltyEB/FullWidthBanner/FullWidthBannerList"
                language: $lang
            ) {
                ...FullWidthBannerList
            }
        }
    `

    const { loading, error, data } = useQuery(GET_DATA)

    useEffect(() => {
        console.log(loading)
        console.log(error?.message)
        console.log(data?.matchpreview?.BannerOrdering?.targetItems)
        if (!loading && !error)
            setBanners(data?.matchpreview?.BannerOrdering?.targetItems)
    }, [loading, error, data])

    return (
        <PriorityCardContainer>
            <BannerTitle>{t('pcarddetail.recommended')}</BannerTitle>
            <Swiper
                style={{ paddingRight: '12px', paddingBottom: '30px' }}
                spaceBetween={8}
                slidesPerView={'auto'}
                slidesOffsetBefore={minW ? 24 : 12}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination, Navigation]}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
            >
                {banners?.length > 0 &&
                    banners.map((banner, index) => {
                        console.log(banner)
                        return (
                            <PriorityBannerItem
                                key={index}
                                onClick={() => {
                                    itemClick(banner)
                                }}
                            >
                                <PriorityCardBannerImg
                                    src={banner?.bannerImage?.src}
                                />
                            </PriorityBannerItem>
                        )
                    })}
            </Swiper>
        </PriorityCardContainer>
    )
}

const PriorityCardContainer = styled.div`
    margin-top: 24rem;
    margin-bottom: 4rem;
    @media ${device.tablet} {
        margin-top: 24px;
        margin-bottom: 8px;
    }
`
const BannerTitle = styled.div`
    font-size: 18rem;
    line-height: 23rem;
    padding-left: 12rem;
    padding-bottom: 8rem;
    color: #000;
    font-weight: 700;
    @media ${device.tablet} {
        font-size: 20px;
        line-height: 26px;
        padding-left: 24px;
        margin-bottom: 8px;
    }
`
const PriorityBannerItem = styled(SwiperSlide)`
    width: 330rem;
    height: 98rem;
    @media ${device.tablet} {
        width: 330px;
        height: 98px;
    }
`
const PriorityCardBannerImg = styled.img`
    height: 100%;
    width: 100%;
    border-radius: 8rem;
    @media ${device.tablet} {
        height: 100%;
        border-radius: 8px;
    }
`

export default PriorityCardBanner
