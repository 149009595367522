import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { device } from 'style/devices'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLoading } from 'components/Loading/context'
import {
    myPrivileges,
    tierBenefitRemarks,
    myOMTPrivileges,
    tierBenefitOmtRemarks,
} from 'mock/fake'
import { updateImagesUrlToPreviewPath } from 'utils/common'
import bridge from 'utils/mobileJSBridge.js'
import TierBenefitIconTitle from 'components/Explanation/TierBenefitIconTitle'
import UnlockIcon from 'assets/icons/unlock.svg'
import SearchIcon from 'assets/icons/search.svg'
import rightarrow from 'assets/icons/rightarrow.png'
import { useNavigate } from 'react-router-dom'

const TierBenefitPage = () => {
    const { i18n, t } = useTranslation()
    const currentLanguage = i18n.language
    const { showLoading, hideLoading } = useLoading()
    const navigate = useNavigate()
    const { models } = useSelector((state) => state.appConfigs)
    const { cuno } = useSelector((state) => state.urlParams)
    const { memberProfile, memberProfileLoading } = useSelector(
        (state) => state.profile
    )
    const pdf = models?.['tier-benefit']?.pdf || {}

    const CardLevel = ['Yellow', 'Red', 'Silver', 'Gold', 'Black']

    const openPDF = () => {
        let targetPdf = pdf?.['public-version']
        if (memberProfile?.memNo) {
            targetPdf = pdf?.['member-version']
        }
        const pdfPath = updateImagesUrlToPreviewPath(
            targetPdf[currentLanguage || 'en-US']
        )
        console.log(pdfPath)
        bridge.openExternalBrowser(pdfPath)
    }

    const toTerms = () => {
        navigate('/terms-conditions')
    }

    const card = useMemo(() => {
        const i = CardLevel.findIndex((x) => memberProfile?.pTier === x)
        const c = CardLevel[i + 1]
        return c || ''
    }, [memberProfile])

    const privileges = useMemo(() => {
        if (memberProfile?.memNo && memberProfile.memNo !== '') {
            return myOMTPrivileges
        } else {
            return myPrivileges
        }
    }, [memberProfile])

    // const remarks = useMemo(() => {
    //     if (memberProfile?.memNo && memberProfile.memNo !== '') {
    //         return tierBenefitOmtRemarks.concat(tierBenefitRemarks)
    //     } else {
    //         return tierBenefitRemarks
    //     }
    // }, [memberProfile])

    useEffect(() => {
        if (!memberProfileLoading) {
            hideLoading()
        } else {
            showLoading()
        }
    }, [memberProfileLoading])

    useEffect(() => {
        window.adobeDataLayer?.push({
            event: 'pageview',
            page: {
                name: '',
                id: 'CWL-ClubWideLoyalty',
                language: i18n.language,
            },
            user: {
                cuno,
                segment_code: memberProfile?.segmentCode,
                pcard_tier: memberProfile?.ptierCode,
            },
            report_suite: '3in1 App',
        })
    }, [])

    if (memberProfileLoading) return null
    return (
        <Container>
            <ExplainationContent>
                <Title>
                    {memberProfile.pTier
                        ? t(`priorityStatus.${memberProfile.pTier}`)
                        : ''}
                </Title>
                <Content>
                    {privileges.map((item, i) => (
                        <Ul key={i}>
                            <TierBenefitIconTitle
                                imgUrl={item.imgUrl}
                                title={t(item.serviceType)}
                            ></TierBenefitIconTitle>
                            <TextBox>
                                {item?.[
                                    memberProfile.pTier
                                ]?.lockPrivileges?.map((d) =>
                                    d.title ? (
                                        <TextListUl key={d.title}>
                                            <SubTilte>{t(d.title)}</SubTilte>
                                        </TextListUl>
                                    ) : (
                                        <TextListUl key={d.text}>
                                            <Dot></Dot>
                                            <Text>{t(d.text)}</Text>
                                        </TextListUl>
                                    )
                                )}
                            </TextBox>
                            {item?.[memberProfile.pTier]?.unLockPrivileges &&
                                card && (
                                    <UnlockBenefit>
                                        <UnlockTipBox>
                                            <UnlockTipTtile>
                                                {t('tierBenefit.advance', {
                                                    card: t(
                                                        `priorityStatus.${card}`
                                                    ),
                                                })}
                                            </UnlockTipTtile>
                                            <UnlockSvg
                                                src={UnlockIcon}
                                            ></UnlockSvg>
                                        </UnlockTipBox>
                                        <UnlockTextBox>
                                            {item?.[
                                                memberProfile.pTier
                                            ].unLockPrivileges?.map((d) => (
                                                <UnlockTipUl key={d.text}>
                                                    <TextListUl>
                                                        <UnlockDot></UnlockDot>
                                                        <UnlockText>
                                                            {t(d.text)}
                                                        </UnlockText>
                                                    </TextListUl>
                                                </UnlockTipUl>
                                            ))}
                                        </UnlockTextBox>
                                    </UnlockBenefit>
                                )}
                        </Ul>
                    ))}
                </Content>
                <RemarkBox>
                    <UnlockTipTtile>{t('tierBenefit.remark')}</UnlockTipTtile>
                    <TextBox>
                        {memberProfile?.memNo && memberProfile.memNo !== '' && (
                            <>
                                {tierBenefitOmtRemarks?.map((d, index) => (
                                    <TextListUl key={d.text}>
                                        <Text>{t(d.text)}</Text>
                                    </TextListUl>
                                ))}
                            </>
                        )}

                        {tierBenefitRemarks?.map((d, index) => (
                            <TextListUl key={d.text}>
                                <Dot></Dot>
                                <Text>
                                    {t(d.text)}
                                    {currentLanguage === 'en-US' ? ' ' : ''}
                                    {index ===
                                        tierBenefitRemarks.length - 1 && (
                                        <OpenLink onClick={toTerms}>
                                            {t('reward.termsAndConditions')}
                                        </OpenLink>
                                    )}
                                    {index ===
                                        tierBenefitRemarks.length - 1 && (
                                        <>
                                            {currentLanguage === 'en-US'
                                                ? '.'
                                                : '約束。​'}
                                        </>
                                    )}
                                </Text>
                            </TextListUl>
                        ))}
                    </TextBox>
                </RemarkBox>

                <DiscoverCardContainer>
                    <DiscoverCard onClick={() => openPDF()}>
                        <DiscoverIcon src={SearchIcon} />
                        <DiscoverText>{t('tierBenefit.discover')}</DiscoverText>
                        <DiscoverButton src={rightarrow}></DiscoverButton>
                    </DiscoverCard>
                </DiscoverCardContainer>
            </ExplainationContent>
        </Container>
    )
}

const Container = styled.div`
    background: #f0f0f0;
`

const Title = styled.div`
    font-weight: 700;
    line-height: 66rem;
    font-size: 16rem;
    text-align: center;
    @media ${device.tablet} {
        line-height: 66px;
        font-size: 16px;
    }
`

const SubTilte = styled.div`
    font-size: 14rem;
    font-weight: 500;
    color: #000;
    margin-bottom: 4rem;
    @media ${device.tablet} {
        font-size: 16px;
        margin-bottom: 4px;
    }
`

const ExplainationContent = styled.div`
    width: 100%;
    padding: 0 16rem 44rem 16rem;
    @media ${device.tablet} {
        padding: 0 20px 44px 20px;
    }
`

const Content = styled.div`
    width: 100%;
    border-radius: 8rem;
    background-color: #fff;
    padding: 28rem 20rem 0 20rem;
    @media ${device.tablet} {
        border-radius: 8px;
        padding: 20px 20px 0 20px;
    }
`

const RemarkBox = styled(Content)`
    padding: 19rem;
    margin-top: 32rem;
    @media ${device.tablet} {
        padding: 20px;
        margin-top: 32px;
    }
`
const Ul = styled.div`
    margin-bottom: 16rem;
    &:last-child {
        border-bottom: none;
    }
    border-bottom: 1px solid #aaaaa0;
    padding-bottom: 16rem;
    @media ${device.tablet} {
        margin-bottom: 16px;
        padding-bottom: 16px;
    }
`

const TextBox = styled.div`
    margin-top: 8rem;
    padding-left: 8rem;
    @media ${device.tablet} {
        margin-top: 10px;
        padding-left: 10px;
    }
`

const UnlockTextBox = styled.div`
    padding-left: 8rem;
    @media ${device.tablet} {
        padding-left: 10px;
    }
`

const TextListUl = styled.div`
    display: flex;
`

const Dot = styled.div`
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background: #000;
    flex-shrink: 0;
    margin-top: 8rem;
    margin-right: 6rem;
    @media ${device.tablet} {
        margin-top: 8px;
        margin-right: 6px;
        width: 4px;
        height: 4px;
    }
`

const UnlockDot = styled(Dot)`
    background: #aaaaa0;
`

const UnlockText = styled.div`
    font-size: 13rem;
    font-weight: 400;
    color: #aaaaa0;
    @media ${device.tablet} {
        font-size: 14px;
    }
`

const Text = styled.div`
    font-size: 13rem;
    font-weight: 400;
    color: #000;
    @media ${device.tablet} {
        font-size: 14px;
    }
`

const UnlockBenefit = styled.div`
    background: #f0f0f0;
    padding: 14rem;
    border-radius: 6rem;
    margin-top: 16rem;
    @media ${device.tablet} {
        padding: 14px;
        border-radius: 6px;
        margin-top: 16px;
    }
`

const UnlockTipBox = styled.div`
    display: flex;
    justify-content: space-between;
`

const UnlockTipTtile = styled.div`
    color: #bd8945;
    font-size: 14rem;
    font-weight: 700;
    @media ${device.tablet} {
        font-size: 18px;
    }
`
const OpenLink = styled.span`
    text-decoration: underline;
`

const UnlockTipUl = styled.div`
    /* padding-left: 4rem; */
`

const UnlockSvg = styled.img`
    width: 38rem;
    height: 24rem;
    @media ${device.tablet} {
        width: 52px;
        height: 32px;
    }
`

const DiscoverCardContainer = styled.div`
    margin-top: 32rem;
    @media ${device.tablet} {
        margin-top: 32px;
    }
`

const DiscoverCard = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-radius: 6rem;
    background-color: white;
    padding: 8rem 0px 8rem 12rem;
    box-shadow: 0px 4px 4px 0px #00000026;
    position: relative;
    @media ${device.tablet} {
        border-radius: 6px;
        background-color: white;
        padding: 8px 0px 8px 12px;
    }
`

const DiscoverIcon = styled.img`
    width: 24rem;
    height: 24rem;
    @media ${device.tablet} {
        width: 24px;
        height: 24px;
    }
`

const DiscoverText = styled.div`
    font-size: 16rem;
    line-height: 19rem;
    letter-spacing: 0.1px;
    text-align: left;
    font-weight: 400;
    margin-left: 8rem;
    @media ${device.tablet} {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0.1px;
        text-align: left;
        margin-left: 8px;
    }
`

const DiscoverButton = styled.img`
    width: 24rem;
    height: 24rem;
    position: absolute;
    right: 12rem;
    @media ${device.tablet} {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 12px;
    }
`

export default TierBenefitPage
