import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
    name: 'urlParams',
    initialState: {
        source: '',
        cuno: '',
    },
    reducers: {
        setSourceType: (state, action) => {
            state.source = action.payload
        },
        setCuno: (state, action) => {
            state.cuno = action.payload
        },
    },
})

export const { setSourceType, setCuno } = slice.actions
export default slice.reducer
